import { classNames } from "helpers";
import { useState } from "react"
import { LazyLoadImage } from 'react-lazy-load-image-component'

export type Koi = {
    id: number;
    num: number;
    gender: string;
    klass: string;
}

type Props = {
    koi: Koi,
    percentage: number;
    size: 'small' | 'full';
    clicked: (koi: Koi) => void;
}
const ListItem = (props: Props) => {

    const { koi, percentage, clicked, size } = props
    const [loaded, setLoaded] = useState<boolean>(false)

    return (
        <>
            <div onClick={() => clicked(koi)} className={classNames(
                typeof clicked === 'function' ? 'cursor-pointer' : '',
                `fade-in-image`
            )} key={koi.id} style={{ position: 'relative', width: percentage + '%', fontSize: '12px' }}>
                {
                    loaded && <>
                        {/* <div className="fade-in-metadata" style={{ borderRadius: '5px', bottom: '5%', right: '65%', color: 'white', padding: '0px 5px', position: 'absolute', background: 'rgba(0,0,0,0.5)', opacity: 0.5 }}>
                        {koi.klass}
                    </div> */}
                        {/* <div className="fade-in-metadata" style={{ borderRadius: '5px', bottom: '5%', left: '70%', color: 'white', padding: '0px 5px', position: 'absolute', background: 'rgba(0,0,0,0.5)', opacity: 0.5 }}>
                            {koi.num}
                        </div> */}
                    </>}

                <LazyLoadImage
                    width='100%'
                    src={`/${size}/${koi.id}.png`}
                    afterLoad={() => setLoaded(true)}
                // effect='blur'
                />
            </div>
        </>
    )
}

export default ListItem