import { classNames } from "helpers";
import { ReactNode } from "react";

export type TabItem = {
    key: string;
    label: string;
    data?: any
}

type Props = {
    items: TabItem[]
    activeKey: string;
    onChanged: (item: TabItem) => void;
    children: ReactNode | ReactNode[]
}
export const Tabs = (props: Props) => {

    const { items, activeKey, onChanged, children } = props

    return <div>
        <div className="tabs">
            {items.map(item => <a
                onClick={() => {
                    onChanged(item)
                }}
                key={item.key} className={classNames(
                    activeKey === item.key ? 'tab-active' : '',
                    `tab`
                )}>{item.label}</a>)}
        </div>
        <div className="mt-5 overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-md bg-base-100/75">
            {children}
        </div>
    </div>
}