export const assets = [{
    name: 'koi_1', url: '/rotated/10.png', type: 'image'
}, {
    name: 'koi_2', url: '/rotated/301.png', type: 'image'
}, {
    name: 'koi_3', url: '/rotated/601.png', type: 'image'
}, {
    name: 'koi_4', url: '/rotated/901.png', type: 'image'
}, {
    name: 'koi_5', url: '/rotated/1211.png', type: 'image'
}, {
    name: 'koi_6', url: '/rotated/1501.png', type: 'image'
}, {
    name: 'koi_7', url: '/rotated/1821.png', type: 'image'
}, {
    name: 'koi_8', url: '/rotated/2102.png', type: 'image'
}, {
    name: 'koi_9', url: '/rotated/2403.png', type: 'image'
}, {
    name: 'stone', url: '/stone.jpg', type: 'image'
}, {
    name: 'leaf1', url: '/leaf1.png', type: 'image'
}, {
    name: 'leaf2', url: '/leaf2.png', type: 'image'
}, {
    name: 'leaf3', url: '/leaf3.png', type: 'image'
}, {
    name: 'leaf4', url: '/leaf4.png', type: 'image'
},  {
    name: 'sound.water-bloop', url: '/water-bloop.mp3', type: 'sound'
}, 
// {
//     name: 'sound.fun-in-kyoto', url: '/fun-in-kyoto.mp3', type: 'sound'
// }, 
{
    name: 'displacement_map_repeat', url: '/displacement_map_repeat.jpg', type: 'image'
}]