import { Loader } from "@pixi/loaders";
import { Sound, sound, SoundLoader, SoundLibrary } from "@pixi/sound";
import { Container, Graphics, Resource } from "pixi.js";
import { assets } from "../assets";
import { IScene } from "../IScene";
import { Manager } from "../Manager";
import { GameScene } from "./GameScene";


export class LoaderScene extends Container implements IScene {

    // for making our loader graphics...
    private loaderBar: Container;
    private loaderBarBoder: Graphics;
    private loaderBarFill: Graphics;


    screenWidth: number;
    screenHeight: number;

    constructor(screenWidth: number, screenHeight: number) {
        super();

        this.screenWidth = screenWidth
        this.screenHeight = screenHeight

        const loaderBarWidth = Manager.width * 0.8;

        this.loaderBarFill = new Graphics();
        this.loaderBarFill.beginFill(0x008800, 1)
        this.loaderBarFill.drawRect(0, 0, loaderBarWidth, 50);
        this.loaderBarFill.endFill();
        this.loaderBarFill.scale.x = 0;

        this.loaderBarBoder = new Graphics();
        this.loaderBarBoder.lineStyle(10, 0x0, 1);
        this.loaderBarBoder.drawRect(0, 0, loaderBarWidth, 50);

        this.loaderBar = new Container();
        this.loaderBar.addChild(this.loaderBarFill);
        this.loaderBar.addChild(this.loaderBarBoder);
        this.loaderBar.position.x = (Manager.width - this.loaderBar.width) / 2;
        this.loaderBar.position.y = (Manager.height - this.loaderBar.height) / 2;
        this.addChild(this.loaderBar);

        // Loader.shared.add(assets);

        // LoaderResource.setExtensionLoadType('mp3', LoaderResource.LOAD_TYPE.XHR)
        // LoaderResource.setExtensionXhrType('mp3', LoaderResource.XHR_RESPONSE_TYPE.BUFFER)


        // sound.useLegacy = false

        const loader = Loader.shared

        // loader.pre(Sound)


        const images = assets.filter(asset => asset.type === 'image')

        loader
            .pre(SoundLoader.pre)
            .use(SoundLoader.use)
            .add(images)
            .load()

        // loader.onLoad.add(this.load, this);
        loader.onProgress.add(this.downloadImagesProgress, this);
        loader.onComplete.add(this.imagesLoaded, this);
        // console.log('called')
    }

    // private load(loader: Loader, resource: any): void {
    //     console.log('loader', loader, resource)
    // }

    private downloadImagesProgress(loader: Loader): void {
        const progressRatio = loader.progress / 200;
        this.loaderBarFill.scale.x = progressRatio;

    }

    private downloadSoundsProgress(downloaded: number, total: number): void {
        const progressRatio = 0.5 + (downloaded/total);
        this.loaderBarFill.scale.x = progressRatio;
    }

    private imagesLoaded(loader: Loader, resources: any): void {


        // console.log('gameLoaded', resources)


        const _assets = assets.filter(asset => asset.type === 'sound')

        let downloaded = 0
        const totalFiles = _assets.length


        for (let asset of _assets) {
            let name = asset.name.split('.')[1]
            sound.add(name, {
                url: asset.url,
                preload: true,
                loaded: () => {
                    downloaded++
                    this.downloadSoundsProgress(downloaded, totalFiles)
                    if(downloaded === totalFiles){
                        Manager.changeScene(new GameScene(this.screenWidth, this.screenHeight));
                    }
                }
            })
        }

    }


    public update(framesPassed: number): void {
        // To be a scene we must have the update method even if we don't use it.
    }

    public resize(screenWidth: number, screenHeight: number): void {
        this.screenWidth = screenWidth
        this.screenHeight = screenHeight
    }
}