import { Box } from "components/common/Box"
import { classNames } from "helpers"
import { ReactNode, useEffect } from "react"

type TableProps = {
    children: ReactNode | ReactNode[]
}

export const table = (props: TableProps) => {
    const { children } = props
    return <Box>
        <table className="bg-base-100/75 text-xs sm:text-sm min-w-full divide-y divide-gray-300">
            {children}
        </table>
    </Box>

}

type RowProps = {
    children: ReactNode | ReactNode[]
}

export const row = (props: RowProps) => {
    const { children } = props
    return <tr className="divide-x divide-gray-200">
        {children}
    </tr>
}

export const thead = (props: { children: ReactNode | ReactNode[] }) => {
    const { children } = props
    return <thead>
        {children}
    </thead>
}


type ThProps = {
    first?: boolean;
    last?: boolean;
    children: ReactNode | ReactNode[]
}

export const th = (props: ThProps) => {
    const { first, last, children } = props

    return <th className={classNames(
        first ? 'sm:pl-6' : '',
        last ? 'sm:pr-6' : '',
        first || last ? 'pl-4 pr-4' : '',
        !first && !last ? 'px-4' : '',
        `py-3.5 text-left font-semibold text-gray-900`
    )}>
        {children}
    </th>
}

export const tbody = (props: { children: ReactNode | ReactNode[] }) => {
    const { children } = props
    return <tbody>
        {children}
    </tbody>
}

type TdProps = {
    first?: boolean;
    last?: boolean;
    children: ReactNode | ReactNode[]
}

export const td = (props: TdProps) => {
    const { first, last, children } = props

    return <td className={classNames(
        first ? 'sm:pl-6' : '',
        last ? 'sm:pr-6' : '',
        first || last ? 'py-4 pl-4 pr-4' : '',
        !first && !last ? 'p-4' : '',
        `text-gray-500`
    )}>
        {children}
    </td>
}


export default {
    table,
    row,
    thead,
    th,
    tbody,
    td
}