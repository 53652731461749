import { classNames } from "helpers"
import { ReactNode } from "react"

type Props = {
    children: ReactNode | ReactNode[]
    type?: 'info'
    [key: string]: any;
}
export const LightBox = (props: Props) => {
    const { children, type, ...rest } = props

    return <div className={
        classNames(
            type === 'info'
                ? ` bg-sky-200/70 text-sky-800 border-sky-200/90`
                : 'bg-base-100/70 border-base-100/90',
            `backdrop-blur-sm border shadow-xl rounded-lg`,
            rest.className || ''
        )}>
        {children}
    </div>
}