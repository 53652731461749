
import { toast as t, ToastPosition } from 'react-toastify';

export function degreesToRadians(degrees: number) {
    var pi = Math.PI;
    return degrees * (pi / 180);
}

export function radiansToDegrees(radians: number) {
    var pi = Math.PI;
    return radians * (180 / pi);
}

export const randomFromInterval = (min: number, max: number) => { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export const randomFloatFromInterval = (min: number, max: number) => { // min and max included 
    return Math.random() * (max - min) + min
}

export const norm = (val: number, min: number, max: number): number => (val - min) / (max - min)


export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export async function sleep(ms: number) {
    await new Promise(resolve => setTimeout(resolve, ms))
}

export const isProd = () => {
    return process.env.NODE_ENV === 'production'
}

export const toast = (type: 'error' | 'success', message: string) => {

    const options = {
        position: 'top-center' as ToastPosition,
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }

    if (type === 'error') {
        t.error(message, options);
    } else if (type === 'success') {
        t.success(message, options);

    }

}

export const promiseToast = (promise: any, pendingMessage: string, successMessage: string, errorMessage?: string) => {
    t.promise(
        promise,
        {
            pending: {
                render() {
                    return pendingMessage
                },
                position: 'top-center' as ToastPosition,
            },
            success: successMessage,
            error: {
                render(obj: any) {
                    const { data } = obj
                    return errorMessage || data.message
                }
            }
        }
    )
}

export const hexStringToUint8Array = (hex: string): Uint8Array => {
    return new Uint8Array(hex.match(/.{1,2}/g)!.map(byte => parseInt(byte, 16)));
}


export const squeezeAddress = (address: string) => {
    return address.substring(0, 5) + '....' + address.substring(address.length - 5, address.length)
}


export const getCdnEndpointByEnv = ()=>{
    return `${process.env.REACT_APP_CDN_ENDPOINT}/${isProd() ? 'Prod' : 'Test'}`
}