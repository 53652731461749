import { Loader } from '@pixi/loaders'
import { sound, Sound } from '@pixi/sound';
import { SOUND_EFFECTS_VOLUME_STORAGE_KEY } from 'config';
import { ShockwaveFilter } from "pixi-filters";
import { Container, filters, Sprite, WRAP_MODES } from "pixi.js";

const { DisplacementFilter } = filters


export class Water {

    container: Container
    displacementFilter: any
    displacementSprite: Sprite = Sprite.from('displacement_map_repeat')
    ripples: ShockwaveFilter[] = []

    constructor(container: Container) {

        this.displacementFilter = new DisplacementFilter(this.displacementSprite);
        this.displacementFilter.padding = 10;
        this.displacementFilter.scale.x = 60;
        this.displacementFilter.scale.y = 60;

        this.displacementSprite.texture.baseTexture.wrapMode = WRAP_MODES.REPEAT
        this.displacementSprite.position = container.position


        this.container = container
        this.container.filters = [this.displacementFilter]
        this.container.addChild(this.displacementSprite)

        const soundEffectsVolume = parseFloat(localStorage.getItem(SOUND_EFFECTS_VOLUME_STORAGE_KEY) as string)/100
        sound.find('water-bloop').volume = isNaN(soundEffectsVolume)? 0.5 : soundEffectsVolume
    }

    ripple(x: number, y: number) {
        const ripple = new ShockwaveFilter([x, y], {
            amplitude: 15,
            wavelength: 50,
            brightness: 1.4,
            radius: 2000,
            speed:1000
        }, 0)

        this.ripples.push(ripple)


        this.container.filters = [this.displacementFilter, ...this.ripples]

        // console.log('test')



        sound.play('water-bloop')


        // Loader.shared.resources.ripple.sound?.play()

        // console.log(Loader.shared.resources['ripple'])
        // const sound = Loader.shared.resources['ripple'].sound

        // console.log('sound', sound)
        // sound?.play()
        // sound.play('ripple-sound')
    }

    update() {
        this.displacementSprite.x += 5
        if (this.displacementSprite.x > this.displacementSprite.width) { this.displacementSprite.x = 0; }


        let _ripples: ShockwaveFilter[] = []
        for (let ripple of this.ripples) {
            ripple.time += 0.01
            ripple.wavelength += 2
            ripple.brightness -= 0.001
            if (ripple.time < 5) {
                _ripples.push(ripple)
            }
        }
        this.ripples = _ripples


    }

    resize(screenWidth: number, screenHeight: number) {
        if (this.displacementSprite) {
            this.displacementSprite.width = screenWidth * 2
            this.displacementSprite.height = screenHeight * 2
        }
    }

}