import { Rectangle } from 'pixi.js';
import Victor from 'victor'
import { degreesToRadians, norm, radiansToDegrees, randomFloatFromInterval, randomFromInterval } from '../helpers';
import { Flagellum } from './Flagellum';


export class Boid extends Flagellum {

    location: Victor;
    velocity: Victor;
    acceleration: Victor;
    maxForce: number;
    maxSpeed: number;
    rushSpeed: number;
    defaultMaxForce: number;
    defaultMaxSpeed: number;
    wandertheta: number = randomFromInterval(0, 360) // not set intially
    paused: boolean = false;

    timeCheck: boolean = false
    timeCount: number = 0
    lastTimeCheck: number = 0
    timeCountLimit: number = 40

    constructor(id: number, scale: number, location: Victor, maxSpeed: number, maxForce: number) {
        super(id, scale)

        this.location = location.clone()
        this.velocity = new Victor(0, 0)
        this.acceleration = new Victor(0, 0);
        this.maxSpeed = maxSpeed;
        this.maxForce = maxForce;
        this.defaultMaxSpeed = maxSpeed
        this.defaultMaxForce = maxForce
        this.rushSpeed = maxSpeed * 2

        this.interactive = true
        this.on('pointerdown', () => {
            this.paused = !this.paused
        })

    }

    steer(target: Victor, slowdown: boolean) { // return PVector


        // let desired = p5.Vector.sub(target, this.location);  // A vector pointing from the location to the target
        // // Normalize desired and scale to maximum speed
        // desired.normalize();
        // desired.mult(this.maxSpeed);
        // // Steering = Desired minus Velocity
        // let steer = p5.Vector.sub(desired, this.velocity);
        // steer.limit(this.maxForce);  // Limit to maximum steering force
        // return steer;

        let steer: Victor
        // console.log('clone',target.clone())
        let desired = target.clone().subtract(this.location);

        let d = desired.magnitude();


        if (d > 0) {
            desired.normalize();

            if (slowdown && d < 100) {
                desired.multiplyScalar(this.maxSpeed * (d / 100));
                // console.log('desired', desired)

            }
            else {
                desired.multiplyScalar(this.maxSpeed);
            }

            steer = desired.subtract(this.velocity);
            steer.limit(this.maxForce, 1);

            // console.log(steer.magnitude())
        }
        else {
            steer = new Victor(0, 0);
        }

        return steer;
    }


    seek(target: Victor) {
        this.acceleration.add(this.steer(target, false))
    }

    arrive(target: Victor) {
        this.acceleration.add(this.steer(target, true))
    }

    flee(target: Victor) {
        this.acceleration.subtract(this.steer(target, false))
    }

    evade(target: Victor) {
        this.timeCheck = true;
        // console.log('distance', target.clone().distance(this.location))
        // if (target.clone().distance(this.location) < 500) {

        let lookAhead = this.location.distance(target) / (this.rushSpeed * 2)
        let predictedTarget = new Victor(target.x - lookAhead, target.y - lookAhead)

        this.flee(predictedTarget);
        // }
    }

    // applyForce(force: Victor) {
    //     // We could add mass here if we want A = F / M
    //     this.acceleration.add(force);
    // }

    wander() {
        let wanderR = 5;
        let wanderD = 100;
        // let change = 0.01;
        let change = 0.05;

        this.wandertheta += randomFloatFromInterval(-change, change);

        let circleLocation = this.velocity.clone();
        circleLocation.normalize();
        circleLocation.multiplyScalar(wanderD);
        circleLocation.add(this.location);

        let circleOffset = new Victor(wanderR * Math.cos(this.wandertheta), wanderR * Math.sin(this.wandertheta));
        let target = circleLocation.add(circleOffset)

        // this.steer(target, true)
        this.seek(target)
        // this.applyForce(this.steer(target, false))
    }


    update(framesPassed: number) {

        if (
            true//!this.paused
        ) {


            // this.wander()


            this.velocity.add(this.acceleration)
            this.velocity.limit(this.maxSpeed, 1)
            this.location.add(this.velocity)


            this.acceleration.multiplyScalar(0)


            this.x = this.location.x
            this.y = this.location.y


            // this.borders()

            let theta = this.velocity.horizontalAngle() + degreesToRadians(180);

            // super.setMuscleFreq(this.velocity.clone().normalize().magnitude() * 0.06 * (this.timeCheck ? 2 : 1))
            super.setTheta(radiansToDegrees(theta) + 180)
            super.update(framesPassed)



            const ms = (framesPassed / 60) * 1000

            // console.log('ms', ms)

            if (this.timeCheck) {
                if (ms > this.lastTimeCheck + 200) {
                    this.lastTimeCheck = ms;

                    if (this.timeCount <= this.timeCountLimit) {
                        // derease maxSpeed in relation with time cicles
                        // this formula needs a proper look
                        let _maxSpeed = this.rushSpeed - (norm(this.timeCount, 0, this.timeCountLimit) * 4);
                        this.maxSpeed = _maxSpeed > (this.defaultMaxSpeed / 2) ? _maxSpeed : (this.defaultMaxSpeed / 2)
                        this.timeCount++;
                    }
                    else if (this.timeCount >= this.timeCountLimit) {
                        // once the time cicle is complete
                        // resets timer variables,
                        this.timeCount = 0;
                        this.timeCheck = false;

                        // set default speed values
                        this.maxSpeed = this.defaultMaxSpeed //random(0.8f, 1.9f);
                        this.maxForce = this.defaultMaxForce //0.2f;
                    }
                }
            }

            // this.renderText()



        }



    }

    renderText() {
        this.infoText.update(`acceleration: ${this.acceleration.magnitude().toFixed(2)}
velocity: ${this.velocity.magnitude().toFixed(2)}
`)

    }
    // lx: ${this.location.x.toFixed(2)}, ly: ${this.location.y.toFixed(2)}
    // kw: ${this.width.toFixed(2)}, kh: ${this.height.toFixed(2)}
    //     bx: ${this.rope.getBounds().x.toFixed(2)}, by: ${this.rope.getBounds().y.toFixed(2)}, bw: ${this.rope.getBounds().width.toFixed(2)}, bh ${this.rope.getBounds().height.toFixed(2)}
    // cx: ${this.getBounds().x.toFixed(2)}, cy: ${this.getBounds().y.toFixed(2)}, cw: ${this.getBounds().width.toFixed(2)}, ch ${this.getBounds().height.toFixed(2)}`)


    getBounds(skipUpdate?: boolean, rect?: Rectangle): Rectangle {
        return this.rope.getBounds()
    }




    bellCurve(ms: number) {
        const a = 2
        const b = 5.6
        const x = ms / 1000
        return Math.pow(Math.E, -Math.pow(x - a, 2) / b)
    }
}