import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import _ from 'lodash'
import { classNames, getCdnEndpointByEnv, toast } from "helpers";
// import data from 'data/data.json'
import { TransparentLayout } from "components/Layout.tsx/TransparentLayout";
import { LightBox } from "components/common/LightBox";
import { Modal } from "components/common/Modal";
import { KoiMiniCard } from "components/common/KoiMiniCard";
import GorillaPoolApi, { Unspent1SatTransaction } from "network/external/GorillaPool.api";
import { useWallet } from "hooks/useWallet";
import { RiCheckboxCircleLine, RiInformationLine, RiLoaderLine } from "@remixicon/react";
import { CopyButton } from "components/common/CopyButton";

// const kois = data.kois

export const OwnerNftsPage = () => {

    const wallet = useWallet({ provider: 'panda' })
    const params = useParams()
    const [items, setItems] = useState<any[]>([])
    const [recipentError, setRecientError] = useState<string>('')

    const [currentKoi, setCurrentKoi] = useState<any>()

    const [koiModalOpened, setKoiModalOpened] = useState<boolean>(false)
    const [sendModalOpened, setSendModalOpened] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [sending, setSending] = useState<boolean>(false)

    const [sentTxid, setSentTxid] = useState<string>('')
    const [recipientAddress, setRecipientAddress] = useState<string>('')

    useEffect(() => {
        if (params.owner) {
            refresh()
        }
    }, [params.owner])

    useEffect(() => {
        if (recipientAddress.includes('$')) {
            setRecientError('Handcash handle is not suppoorted')
        } else if (recipientAddress.includes('@')) {
            setRecientError('Paymail is not suppoorted')
        } else if (!/^1[1-9A-HJ-NP-Za-km-z]{25,34}$/.test(recipientAddress)) {
            setRecientError('Invalid bitcoin address')
        } else {
            setRecientError('')
        }
    }, [recipientAddress])

    const refresh = async () => {

        try {

            // const idKeyAddress = '1PZbNxDPpfAiBaTf7J6cbvHWfPKpmpdg6t'

            setLoading(true)

            // const address = '1srtLeZiJ5FbKVS3YQ2yPRVkc3p7vciu3' //currentUser.ownerAddress
            const items = await GorillaPoolApi.getCollectionItemsByCollectionId(process.env.REACT_APP_KOI_COLLECTION_ID as string, params.owner as string)

            setItems(items)

            setLoading(false)


        } catch (err) {
            // console.log(err)
            setLoading(false)
            toast('error', 'Something went wrong. Please contact support.')
        }

    }

    const send = async (item: Unspent1SatTransaction, to: string) => {
        try {
            setSending(true)

            const txid = await wallet.transferOrdinal({
                address: to,
                origin: item.origin.outpoint,
                outpoint: item.outpoint
            })

            setSentTxid(txid)

            const _items = items.filter((_item: Unspent1SatTransaction) => _item.origin.outpoint !== item.origin.outpoint)

            setItems(_items)

            toast('success', `Your koi nft has been successfully sent.`)
        } catch (err) {
            if ((err as any).response?.data?.message) {
                toast('error', (err as any).response.data.message)
            } else {
                toast('error', 'Something went wrong. Please contact support.')
            }
        } finally {
            setSending(false)
        }
    }


    const loadingTag = () => {
        return <LightBox className='py-8'>
            <div className="flex items-center justify-center p-5" style={{ width: '100%' }}>
                <RiLoaderLine size={24} color="gray" className="animate-spin mr-2" />
                <p className="text-center text-xl font-bold">Loading...</p>
            </div>
            <div className="flex justify-center">
                <p className="text-center font-bold text-sm">This might take several minutes to complete the first time you view it.</p>
            </div>
        </LightBox>
    }

    return (
        <>
            <Modal
                size="lg"
                show={koiModalOpened}
                onClose={() => {
                    setKoiModalOpened(false)
                }}
            >
                <div style={{ backgroundColor: '#ADD8E6' }}>
                    {currentKoi && <img src={`${getCdnEndpointByEnv()}/inscriptions/koi/full-vertical/${currentKoi.origin.outpoint}.png`} />}
                </div>
            </Modal>
            <Modal
                title='Send to Ordinal address'

                show={sendModalOpened}
                onClose={() => {
                    setSendModalOpened(false)
                    setCurrentKoi(undefined)
                    setSentTxid('')
                }}
                size='3xl'
            >
                <div>
                    {sentTxid && <div className="alert alert-success mt-4">
                        <div>
                            <RiCheckboxCircleLine size={40} color="white" className="mx-2" />
                            <div className=" text-base-100">
                                <p><strong>Transaction ID:</strong></p>
                                <p className="mb-1 text-sm text-break">{sentTxid}</p>
                                <p className="mb-1 text-sm">View on <a className='underline font-bold' href={`https://whatsonchain.com/tx/${sentTxid}`} target="_blank" rel="noopener">WhatsOnChain</a></p>
                                {/* <p className="mb-1 text-sm">View on <a className='underline font-bold' href={`https://run.network/explorer/?query=${sentTxid}&network=main`} target="_blank" rel="noopener">Run</a></p> */}
                            </div>
                        </div>
                    </div>}
                    {!sentTxid && <>
                        <div className="flex mt-4">
                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">Recipient address</span>
                                </label>
                                <div className="flex space-x-2">
                                    <input

                                        disabled={sending}
                                        type="text"
                                        placeholder="Type here"
                                        className={classNames(
                                            `input input-bordered w-full`,
                                            !!recipentError && !!recipientAddress ? 'input-error' : ''
                                        )}
                                        onChange={e => setRecipientAddress(e.target.value)}
                                    />
                                    <div className="w-40">
                                        <button
                                            onClick={() => send(currentKoi, recipientAddress)}
                                            disabled={!recipientAddress || sending || !!recipentError}
                                            type="button"
                                            className={classNames(
                                                `btn w-full`,
                                                sending ? 'loading' : ''
                                            )}
                                        >
                                            {sending ? 'Sending' : 'Send'}
                                        </button>
                                    </div>
                                </div>

                                {!!recipentError && !!recipientAddress && <label className="label">
                                    <span className="label-text-alt text-error">{recipentError}</span>
                                </label>}
                            </div>

                        </div>
                        <div className="alert mt-4 text-xs sm:text-sm">
                            <div className="block">
                                <p className="mb-0 mt-2"><strong>Important!</strong></p>
                                <ul className="ml-4 list-disc">
                                    <li>Only send the NFT to an Ordinal Address!</li>
                                    <li>Sending to other addresses may result in a loss of your NFT.</li>
                                </ul>
                            </div>
                        </div>

                    </>}

                </div>
            </Modal>

            <TransparentLayout heading={
                <span className="flex sm:flex-row flex-col items-center space-x-2">
                    <span className="text-sm text-neutral">[ Address ]</span>
                    <span className="sm:text-lg text-sm">{params.owner}</span>
                    <CopyButton value={params.owner as string} size={16} color="white" />
                </span>
            }>

                <div className="mb-0" style={{ position: 'relative', zIndex: 2 }}>
                    {loading && loadingTag()}

                    {!loading && <LightBox type="info" className='py-4 mb-2'>
                        <div className="flex items-center px-4 text-sm">
                                <RiInformationLine size={16} className="mr-2" />
                                If the NFTs are not displaying and have just been minted, they will appear after a block confirmation.
                        </div>
                    </LightBox>}


                    {!loading && <div className="d-flex flex-row flex-wrap bd-highlight" style={{ backgroundColor: 'ADD8E6' }}>
                        {items.length === 0 &&
                            <LightBox className='py-8'>
                                <div className="p-5">
                                    <div className="flex items-center justify-center">
                                        <RiInformationLine size={40} className="mr-2" />
                                        <p className="font-bold">
                                            There are no koi fishes associated with this address.
                                        </p>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <div className="space-y-2">
                                            <p className="font-bold">

                                                Buy one <Link to='/buy' className="font-bold text-error">
                                                    here
                                                </Link>.
                                            </p>
                                            <button className='btn' onClick={() => { refresh() }}>Refresh</button>

                                        </div>
                                    </div>
                                </div>
                            </LightBox>
                        }


                        {items.length > 0 && <div>
                            <div className='grid grid-cols-12 gap-2'>
                                {!loading && items.map(item =>
                                    <KoiMiniCard
                                        key={item.origin.outpoint}
                                        item={item}
                                        onSelect={(item) => {
                                            setCurrentKoi(item)
                                            setKoiModalOpened(true)
                                        }}
                                        onSend={(item) => {
                                            setCurrentKoi(item)
                                            setSendModalOpened(true)
                                        }} />
                                )}
                            </div>
                        </div>}

                    </div>}

                </div>
            </TransparentLayout >
        </>

    );
}