import { RiDiscordFill, RiMediumFill, RiTwitterXFill } from "@remixicon/react"
import moment from "moment"

export const Footer = () => {

    const renderSocialButtons = () => {
        return <div className="flex">
            <p className="text-white font-bold" style={{ marginRight: '10px' }}><small>FOLLOW US ON:</small></p>
            <a href='https://twitter.com/PokBiz' className="text-white" target='_blank' rel="noopener">
               <RiTwitterXFill size={24} className="mr-3" />
            </a>
            <a href='https://discord.gg/v5wuT3rApy' className="text-white" target='_blank' rel="noopener">
                <RiDiscordFill size={24} className="mr-3"/>
            </a>
            <a href='https://medium.com/@PokBiz' className="text-white" target='_blank' rel="noopener">
                <RiMediumFill  size={24}/>
            </a>
        </div>
    }

    const renderCopyRight = () => {
        return <p className="text-white">pok.business © {/*2021-*/}{moment().format('YYYY')} | All Rights Reserved</p>
    }
    return <div className="relative">
        <div className="flex flex-wrap justify-between mt-2">
            {renderSocialButtons()}
            {renderCopyRight()}
        </div>
    </div>
}