
export const Shusui = () => {
    return (
        <div className="grid grid-cols-12 gap-2 p-3">
            <div className="col-span-12 sm:col-span-5 pt-3">
                <p className="mb-2"><strong>Scaleless, or doitsu, version of Asagi.</strong></p>

                <p>{`Shusui koi have been crossbred between Doitsu Koi and Asagi. All criteria and characteristics of Asagi also apply to Shusui except there is no netting pattern but a sky blue top half of the koi.`}</p>
                <p>{`A good quality Shusui should have a nice clean head and the white-edged blue scales along the dorsal line should be evenly sized and well placed. It is desirable that scales are visible only at the back and the regions of lateral lines -- no undesirable scales in any other place. Red on the belly covering over the lateral lines are showy.`}</p>
                <p>{`Several subvarieties are found, such as the Hi-Shusui where the red spreads up the body to the dorsal line, and the Hana-Shusui, there a line of red appears on the blue, along both sides of the body between the lateral and dorsal lines.`}</p>
            </div>

            <div className="col-span-12 sm:col-span-7 flex justify-center pt-2 flex-wrap">
                <div className="text-center">
                    <img src="/full/1807.png" width={200} />
                    <p>Shusui</p>
                </div>
                <div className="text-center">
                    <img src="/full/1954.png" width={200} />
                    <p>Hana Shusui</p>
                </div>
                <div className="text-center">
                    <img src="/full/2005.png" width={200} />
                    <p>Hi Shusui</p>
                </div>

            </div>
        </div>
    );
}