type Props = {
    bg: string;
    children: any;
    [key: string]: any;
}
export const Badge = (props: Props) => {
    const { bg, children, ...rest } = props
    return <span {...rest} className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${bg} text-gray-800`}>
        {children}
    </span>
}