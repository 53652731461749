import { Modal, ModalSize } from 'components/common/Modal';
import React, { createContext, useContext, useState, ReactNode } from 'react';


// Define the shape of the modal context
type ModalContextType = {
    showModal: (title: string | ReactNode, content: ReactNode, size: ModalSize) => void;
    hideModal: () => void;
};

// Create the modal context
const ModalContext = createContext<ModalContextType | undefined>(undefined);

// Create a custom hook to use the modal context
export const useModal = () => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error('useModal must be used within a ModalProvider');
    }
    return context;
};

type Props = {
    children: ReactNode
}
// ModalProvider component
export const ModalProvider = (props: Props) => {
    const { children } = props
    const [modalContent, setModalContent] = useState<ReactNode | null>(null);
    const [active, setActive] = useState<boolean>(false)
    const [size, setSize] = useState<ModalSize>()
    const [title, setTitle] = useState<string | ReactNode>()

    // Function to show modal
    const showModal = (title: string | ReactNode, content: ReactNode, size: ModalSize) => {
        setModalContent(content);
        setSize(size)
        setTitle(title)
        setActive(true)
    };

    // Function to hide modal
    const hideModal = () => {
        setModalContent(null);
        setActive(false)
        setSize(undefined)
        setTitle(undefined)
    };

    return (
        <ModalContext.Provider value={{ showModal, hideModal }}>
            {children}
            {modalContent && (
                <Modal
                    title={title}
                    size={size as ModalSize}
                    show={active}
                    onClose={() => {
                        hideModal()
                    }}
                >
                    {modalContent}
                </Modal>

            )}
        </ModalContext.Provider>
    );
};
