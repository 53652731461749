
export const Kohaku = () => {
    return (
        <div className="grid grid-cols-12 gap-2 p-3">
            <div className="col-span-12 sm:col-span-6 pt-3">
                <p className="mb-2"><strong>White koi with red dorsal pattern</strong></p>

                <p>{`Kohaku may be the most recognized variety of koi fish found in Japanese koi ponds. When you search for quality koi for sale, look for Kohaku koi that have pleasing and interesting hi pattern elements.`}</p>
                <p>{`The Kohaku is the most popular variety of Nishikigoi, (Koi) due to its simplicity and elegance. It is also the most abstruse. There are various tones of "red" (hē) color - red with thick crimson, light red, highly homogeneous red, blurred red, and so on. There are all sorts of "Kiwa (the edge of the pattern)" -scale-wide Kiwa, razor-sharp Kiwa, and Kiwa resembling the edge of a torn blanket, etc.`}</p>
                <p>{`Shades of Shiroji, white ground (skin), are quite diversified too -- skin with soft shade of fresh-unshelled, hard boiled egg, skin with hard shade of porcelain, yellowish skin, and so forth.`}</p>
            </div>

            <div className="col-span-12 sm:col-span-6 flex justify-center pt-2 flex-wrap">
                <div className="text-center">
                    <img src="/full/7.png" width={200} />
                    <p className="m-0">Kohaku</p>
                    <p className="text-secondary" style={{ fontSize: '12px' }}>Common scales</p>
                </div>
                <div className="text-center">
                    <img src="/full/1.png" width={200} />
                    <p className="m-0">Kohaku</p>
                    <p className="text-secondary" style={{ fontSize: '12px' }}>Doitsu scales</p>
                </div>

            </div>
        </div>
    );
}