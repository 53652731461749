import { Box } from "components/common/Box";
import { Tabs } from "components/common/Tabs";
import { ContentLayout } from "components/Layout.tsx/ContentLayout";
import _ from "lodash";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Asagi } from "./Asagi";
import { Bekko } from "./Bekko";
import { Goshiki } from "./Goshiki";
import { Kohaku } from "./Kohaku";
import { Koromo } from "./koromo";
import { Sanke } from "./Sanke";
import { Showa } from "./Showa";
import { Shusui } from "./Shusui";
import { Utsuri } from "./Utsuri";

const klasses = [
    'kohaku',
    'bekko',
    'utsuri',
    'sanke',
    'showa',
    'asagi',
    'shusui',
    'goshiki',
    'koromo'
]

export const KoiClassesPage = () => {

    const { klass } = useParams()
    // console.log('klass', klass)
    const navigate = useNavigate()

    return (
        <ContentLayout heading="Koi classes">
            <div>
                <Tabs items={klasses.map(klass => {
                    return {
                        label: _.upperFirst(klass),
                        key: klass
                    }
                })}
                    activeKey={klass as string}
                    onChanged={(item => navigate(`/koi-classes/${item.key}`))}>
                    <div className="px-4">
                        {klass === 'kohaku' && <Kohaku />}
                        {klass === 'asagi' && <Asagi />}
                        {klass === 'bekko' && <Bekko />}
                        {klass === 'goshiki' && <Goshiki />}
                        {klass === 'koromo' && <Koromo />}
                        {klass === 'sanke' && <Sanke />}
                        {klass === 'showa' && <Showa />}
                        {klass === 'shusui' && <Shusui />}
                        {klass === 'utsuri' && <Utsuri />}
                    </div>

                </Tabs>

            </div>
        </ContentLayout>
    );
}