import { Dialog, Transition } from "@headlessui/react"
import { RiCloseLine } from "@remixicon/react";

import { Fragment, ReactNode } from "react"

export type ModalSize = 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'

type Props = {
    title?: string | ReactNode;
    show: boolean;
    onClose: () => void;
    children: ReactNode;
    size: ModalSize;
}

export const Modal = (props: Props) => {

    const { title, children, show, onClose, size } = props

    return <Transition appear show={show} as={Fragment}>
        <Dialog
            as='div'
            onClose={onClose}
            className="relative z-50"
        >

            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black bg-opacity-25" aria-hidden="true" />
            </Transition.Child>


            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >

                        <Dialog.Panel className={`max-w-${size} transform overflow-hidden rounded-2xl bg-base-100 p-6 text-left align-middle shadow-xl transition-all`}>
                            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                <button
                                    type="button"
                                    className="w-7 h-7 flex items-center justify-center bg-white rounded-full text-gray-400 hover:text-gray-500 focus:outline-none"
                                    onClick={onClose}
                                >
                                    <span className="sr-only">Close</span>
                                   <RiCloseLine size={16} color='gray' />
                                </button>
                            </div>
                            {title && <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                            >
                                {title}
                            </Dialog.Title>}
                            {/* <Dialog.Description> */}
                                {children}
                            {/* </Dialog.Description> */}
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>

        </Dialog>

    </Transition>
}