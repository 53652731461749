import { useEffect, useState } from "react";
import _ from 'lodash'
import { getCdnEndpointByEnv, toast } from "helpers";
import { TransparentLayout } from "components/Layout.tsx/TransparentLayout";
import { LightBox } from "components/common/LightBox";
import { Modal } from "components/common/Modal";
import { KoiMiniCard } from "components/common/KoiMiniCard";
import GorillaPoolApi from "network/external/GorillaPool.api";
import { useWallet } from "hooks/useWallet";
import { RiInformationLine, RiLoaderLine } from "@remixicon/react";

export const MarketplacePage = () => {

    const wallet = useWallet({ provider: 'panda' })
    const [items, setItems] = useState<any[]>([])

    const [currentKoi, setCurrentKoi] = useState<any>()
    const [koiModalOpened, setKoiModalOpened] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)


    useEffect(() => {
        refresh()
    }, [])


    const refresh = async () => {

        try {

            setLoading(true)

            const items = await GorillaPoolApi.getMarketListing(process.env.REACT_APP_KOI_COLLECTION_ID as string)

            setItems(items)

            setLoading(false)


        } catch (err) {
            // console.log(err)
            setLoading(false)
            toast('error', 'Something went wrong. Please contact support.')
        }

    }

    const loadingTag = () => {
        return <LightBox className='py-8'>
            <div className="flex items-center justify-center p-5" style={{ width: '100%' }}>
                <RiLoaderLine size={24} color="gray" className="animate-spin mr-2" />
                <p className="text-center text-xl font-bold">Loading...</p>
            </div>
            <div className="flex justify-center">
                <p className="text-center font-bold text-sm">This might take several minutes to complete the first time you view it.</p>
            </div>
        </LightBox>
    }

    return (
        <>
            <Modal
                size="lg"
                show={koiModalOpened}
                onClose={() => {
                    setKoiModalOpened(false)
                }}
            >
                <div style={{ backgroundColor: '#ADD8E6' }}>
                    {currentKoi && <img src={`${getCdnEndpointByEnv()}/inscriptions/koi/full-vertical/${currentKoi.origin.outpoint}.png`} />}
                </div>
            </Modal>


            <TransparentLayout heading={
                <span className="flex items-center space-x-2">
                    <span>Marketplace</span>
                </span>
            }>

                <div className="mb-0" style={{ position: 'relative', zIndex: 2 }}>
                    {loading && loadingTag()}


                    {!loading && <div className="d-flex flex-row flex-wrap bd-highlight" style={{ backgroundColor: 'ADD8E6' }}>
                        {items.length === 0 &&
                            <LightBox className='py-8'>
                                <div className="p-5">
                                    <div className="flex items-center justify-center">
                                        <RiInformationLine size={40} className="mr-2" />
                                        <p className="font-bold">
                                            There are no koi fishes available for sale.
                                        </p>
                                    </div>
                                </div>
                            </LightBox>
                        }
                        {items.length > 0 && <div>
                            <div className='grid grid-cols-12 gap-2'>
                                {!loading && items.map(item =>
                                    <KoiMiniCard
                                        key={item.origin.outpoint}
                                        item={item}
                                        onSelect={(item) => {
                                            setCurrentKoi(item)
                                            setKoiModalOpened(true)
                                        }}
                                        onBuy={async (item) => {
                                            try {
                                                await wallet.purchaseOrdinal({
                                                    outpoint: item.outpoint,
                                                    marketplaceRate: 0.0275,
                                                    marketplaceAddress: process.env.REACT_APP_ROYALTY_ADDRESS
                                                })

                                                const updatedItems = items.filter(_item => _item.origin.outpoint !== item.origin.outpoint);

                                                setItems(updatedItems)

                                            } catch (err) {
                                                if (err === 'User dismissed the request!') {
                                                    console.log(err)
                                                }
                                            }
                                        }} />
                                )}
                            </div>
                        </div>}

                    </div>}

                </div>
            </TransparentLayout >
        </>

    );
}