import { sound } from "@pixi/sound";
import { MUSIC_VOLUME_CHANGE_EVENT, MUSIC_VOLUME_STORAGE_KEY, SOUND_EFFECTS_VOLUME_CHANGE_EVENT, SOUND_EFFECTS_VOLUME_STORAGE_KEY } from "config";
import { randomFloatFromInterval, randomFromInterval } from "helpers";
import { Container, Graphics, Sprite, filters, Texture, TilingSprite, WRAP_MODES } from "pixi.js";
import Victor from "victor";
import { Boid } from "../Boid";
import { IScene } from "../IScene";
import { LotusLeaf } from "../LotusLeaf";
import { Stone } from "../Stone";
import { Water } from "../Water";

export class GameScene extends Container implements IScene {

    framesPassed: number = 0
    screenWidth: number;
    screenHeight: number;

    b: Graphics;

    kois: Boid[] = []
    // lotusLeafs: LotusLeaf[] = []

    water: Water;
    stone: Stone;

    lotusLeafs: LotusLeaf[] = []


    press: boolean = false
    mouseAvoidScope: number = 100;
    mouseAvoidTarget: Victor = new Victor(0, 0)

    constructor(screenWidth: number, screenHeight: number) {
        super();

        this.screenWidth = screenWidth
        this.screenHeight = screenHeight




        const container = new Container()

        this.water = new Water(container)

        this.stone = new Stone(container)


        for (let num of [
            // 1,4,5,
            // 2,3,6,7,8,9
        ]) {
            let location = new Victor(randomFromInterval(0, this.screenWidth), randomFromInterval(0, this.screenHeight))
            let scale = randomFloatFromInterval(0.1, 0.3) //randomFloatFromInterval(0.35, 0.6)
            let koi = new Boid(num, scale, location, 1.9, 0.2)
            this.kois.push(koi)
            container.addChild(koi)
        }
        this.b = new Graphics()



        container.addChild(this.b)
        this.addChild(container)

        // this.generateLotusLeafs()

        // this.addChild(this.lotusLeaf)


        this.setupActivity()

        // const musicVolume = parseFloat(localStorage.getItem(MUSIC_VOLUME_STORAGE_KEY) as string)/100

        // if(musicVolume > 0){
        //     sound.play('fun-in-kyoto', {
        //         loop: true,
        //         volume: musicVolume
        //     })
        // }else if(isNaN(musicVolume)){
        //     sound.play('fun-in-kyoto', {
        //         loop: true,
        //         volume: 0.5
        //     })
        // }
       

        // window.addEventListener(MUSIC_VOLUME_CHANGE_EVENT, () => {
        //     const musicVolume = localStorage.getItem(MUSIC_VOLUME_STORAGE_KEY)
        //     const _sound = sound.find('fun-in-kyoto')
        //     _sound.volume = parseFloat(musicVolume as string)/100
        //     if(!_sound.isPlaying){
        //         _sound.play()
        //         _sound.loop = true
        //     }
        // })

        window.addEventListener(SOUND_EFFECTS_VOLUME_CHANGE_EVENT, () => {
            const soundEffectsVolume = localStorage.getItem(SOUND_EFFECTS_VOLUME_STORAGE_KEY)
            sound.find('water-bloop').volume = parseFloat(soundEffectsVolume as string)/100
        })
    }



    generateLotusLeafs() {
        for (let i = 0; i < 10; i++) {

            let inserted = false

            while (!inserted) {
                const lotusLeaf = new LotusLeaf(Math.random() * this.screenWidth, Math.random() * this.screenHeight)

                let notCollied = true

                for (let _lotusLeaf of this.lotusLeafs) {
                    if (_lotusLeaf.collide(lotusLeaf)) {
                        notCollied = false
                    }
                }

                if (notCollied) {
                    this.lotusLeafs.push(lotusLeaf)
                    this.addChild(lotusLeaf)
                    inserted = true
                }
            }
        }
    }

    setupActivity() {
        // Opt-in to interactivity
        this.interactive = true;
        this.on('pointerdown', (event) => {
            if(event.data.button !== 2){
                // console.log('event', event)
                this.press = true
                this.mouseAvoidTarget = new Victor(event.data.global.x, event.data.global.y)
                this.water.ripple(event.data.global.x, event.data.global.y)
            }
        });

        this.on('pointerup', (event) => {
            this.press = false
            // console.log('pointerup')
        })
    }


    public update(framesPassed: number): void {

        this.framesPassed += Math.round(framesPassed)

        for (let koi of this.kois) {
            this.resetLocationByBorder([koi])


            if (this.press) {
                if (this.mouseAvoidTarget.distance(koi.location) < this.mouseAvoidScope) {
                    koi.timeCount = 0
                    koi.evade(this.mouseAvoidTarget)
                }
            } else {
                koi.wander()
            }

            koi.update(this.framesPassed)





            let { x, y, width, height } = koi.rope.getBounds()

            // this.g.moveTo(0-this.x, 0-this.y);

            this.b.clear();
            // console.log(x, y)
            this.b.beginFill(0xffffff);
            this.b.drawCircle(x, y, 10);
            this.b.endFill();

            this.b.beginFill(0x00ff00);
            this.b.drawCircle(x + width, y, 10);
            this.b.endFill();

            this.b.beginFill(0x0000ff);
            this.b.drawCircle(x + width, y + height, 10);
            this.b.endFill();

            this.b.beginFill(0x000000);
            this.b.drawCircle(x, y + height, 10);
            this.b.endFill();
        }



        this.water.update()

        for (let lotusLeaf of this.lotusLeafs) {
            lotusLeaf.update()
        }


    }

    public resetLocationByBorder(boids: Boid[]) {
        for (let boid of boids) {

            const { x, y, width, height } = boid.getBounds()

            if (x + width < 0) {
                boid.location.x = this.screenWidth;
            }
            if (y + height < 0) {
                boid.location.y = this.screenHeight;
            }
            if (x > this.screenWidth) {
                boid.location.x = 0;
            }
            if (y > this.screenHeight) {
                boid.location.y = 0;
            }
        }
    }

    public resize(screenWidth: number, screenHeight: number): void {
        this.screenWidth = screenWidth
        this.screenHeight = screenHeight


        this.water.resize(screenWidth, screenHeight)
        this.stone.resize(screenWidth, screenHeight)
    }
}