import Navbar from "components/Navbar"
import { AppWrapper } from "context/AppContext"
import { NftDropsWrapper } from "context/NftDropsContext"
import { AboutPage } from "pages/About"
import { GlossaryPage } from "pages/Glossary"
import { HomePage } from "pages/Home"
import { KoiClassesPage } from "pages/KoiClasses/index"
// import { AuthSuccessPage } from "pages/AuthSuccess"
import { NFTPage } from "pages/NFT"
import { Route, Routes } from "react-router-dom"
import { SettingsPage } from "pages/Settings"
import { BuyPage } from "pages/Buy/index"
import { RefCodeRedirectPage } from "pages/Buy/RefCodeRedirectPage"
import { OwnerNftsPage } from "pages/Owner/OwnerNfts"
import { TestPage } from "pages/Gaming/test"
import { PandaProvider } from "panda-wallet-provider";
import { MarketplacePage } from "pages/Marketplace"
import { ModalProvider } from "context/ModalContext"

const Main = () => {
    return <div>
        <PandaProvider>
            <AppWrapper>
                <ModalProvider>
                    <NftDropsWrapper>
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/glossary" element={<GlossaryPage />} />
                            <Route path="/koi-classes/:klass" element={<KoiClassesPage />} />
                            <Route path="/about" element={<AboutPage />} />
                            <Route path="/nft" element={<NFTPage />} />
                            <Route path="/settings" element={<SettingsPage />} />
                            {/* <Route path="/auth-success" element={<AuthSuccessPage />} /> */}
                            <Route path="/buy/:refCode" element={<RefCodeRedirectPage />} />
                            <Route path="/buy" element={<BuyPage />} />
                            <Route path="/marketplace" element={<MarketplacePage />} />
                            <Route path="/:owner/nfts" element={<OwnerNftsPage />} />
                            <Route path="/gaming/test" element={<TestPage />} />
                        </Routes>
                    </NftDropsWrapper>
                </ModalProvider>
            </AppWrapper>
        </PandaProvider>
    </div>
}

export default Main