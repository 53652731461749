import { AxiosResponse } from 'axios';
import { network } from './index';

interface Metadata {
    app: string;
    type: string;
    royalties: {
        type: string;
        destination: string;
        percentage: string;
    }[];
    name: string;
    subType: string;
    subTypeData: {
        collectionId: string;
        traits: {
            name: string;
            value: string;
        }[];
    };
}

export interface InitialSaleNFT {
    _id: string;
    collectionId: string;
    id: string;
    outpoint: string;
    sent: boolean;
    recipient: string;
    referral: string;
    metadata: Metadata;
    createdAt: string;
    updatedAt: string;
}

interface BuyResponse {
    nft: InitialSaleNFT;
    txid: string;
}

interface CountResponse {
    count: number;
}

interface BuyPayload {
    rawtx: string;
    utxos: any[];
    collectionId: string;
    refCode: string;
    koiId?: number;
}



export async function buy(payload: BuyPayload): Promise<BuyResponse> {
    try {
        const response: AxiosResponse<any> = await network.post(`/initial-sale-nft/buy`, payload);
        // console.log('data', response.data);
        response.data.nft.metadata = JSON.parse(response.data.nft.metadata as string)
        return response.data;
    } catch (error) {
        console.error('Error buying:', error);
        throw error;
    }
}
export async function count(collectionId: string): Promise<CountResponse> {
    try {
        const response: AxiosResponse<CountResponse> = await network.get(`/initial-sale-nft/count`, {
            params: {
                collectionId
            }
        });
        // console.log('data', response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching count:', error);
        throw error;
    }
}

export default { buy, count }