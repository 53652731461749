import React, { Component, ReactNode } from 'react'

const slider = React.createRef();
const container = React.createRef();
const isTouchDevice = 'ontouchstart' in document.documentElement

type Props = {
    showReset: boolean;
    disabled: boolean;
    color: string;
    text: ReactNode;
    text_unlocked: ReactNode;
    onSuccess: () => void;
    onFailure: () => void;
    onReset: ()=>void;
}

type State = {
    unlocked: boolean
}

export default class ReactSwipeButton extends Component<Props, State> {

    isDragging = false;
    sliderLeft = 0;
    containerWidth = 0
    unmounted = false
    startX = 0

    state: State = {
        unlocked: false
    }

    componentDidMount() {
        if (isTouchDevice) {
            document.addEventListener('touchmove', this.onDrag);
            document.addEventListener('touchend', this.stopDrag);
        } else {
            document.addEventListener('mousemove', this.onDrag);
            document.addEventListener('mouseup', this.stopDrag);
        }
        this.containerWidth = (container.current as any).clientWidth - 50;
    }

    onDrag = (e: any) => {
        if (this.unmounted || this.state.unlocked || this.props.disabled) return;
        if (this.isDragging) {
            if (isTouchDevice) {
                this.sliderLeft = Math.min(Math.max(0, e.touches[0].clientX - this.startX), this.containerWidth);
            } else {
                this.sliderLeft = Math.min(Math.max(0, e.clientX - this.startX), this.containerWidth);
            }
            this.updateSliderStyle();
        }
    }

    updateSliderStyle = () => {
        if (this.unmounted || this.state.unlocked) return;
        (slider.current as any).style.left = (this.sliderLeft + 50) + 'px';
    }

    stopDrag = () => {
        if (this.unmounted || this.state.unlocked) return;
        if (this.isDragging) {
            this.isDragging = false;
            if (this.sliderLeft > this.containerWidth * 0.9) {
                this.sliderLeft = this.containerWidth;
                this.onSuccess();
                if (this.props.onSuccess) {
                    this.props.onSuccess();
                }
            } else {
                this.sliderLeft = 0;
                if (this.props.onFailure) {
                    this.props.onFailure();
                }
            }
            this.updateSliderStyle();
        }
    }

    startDrag = (e: any) => {
        if (this.unmounted || this.state.unlocked || this.props.disabled) return;
        this.isDragging = true;
        if (isTouchDevice) {
            this.startX = e.touches[0].clientX;
        } else {
            this.startX = e.clientX;
        }
    }

    onSuccess = () => {
        (container.current as any).style.width = (container.current as any).clientWidth + 'px';
        this.setState({
            unlocked: true
        })
    }

    getText = () => {
        return this.state.unlocked 
        ? (this.props.text_unlocked || 'UNLOCKED') 
        : (this.props.text || 'SLIDE')
    }

    reset = () => {
        if (this.unmounted) return;
        this.setState({ unlocked: false }, () => {
            this.sliderLeft = 0;
            this.updateSliderStyle();
            this.props.onReset()
        });
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    render() {
        return (
            <div style={{cursor: this.props.disabled && !this.state.unlocked? 'not-allowed': ''}}  className={'ReactSwipeButton'}>
                <div className={'rsbContainer' + ' ' + (this.state.unlocked ? 'rsbContainerUnlocked' : '')} ref={container as any}>
                    <div  className={'rsbcSlider'}
                        ref={slider as any}
                        onMouseDown={this.startDrag}
                        style={{ background: this.props.color }}
                        onTouchStart={this.startDrag}>
                        <span className={'rsbcSliderText flex items-center justify-center'}>{this.getText()}{this.props.showReset && <span onClick={()=>this.reset()}  style={{cursor: 'pointer', marginLeft: '5px', textDecoration: 'underline'}}>Reset</span>}</span>
                        <span className={'rsbcSliderArrow'}></span>
                        <span className={'rsbcSliderCircle'} style={{ background: this.props.color }}></span>
                    </div>
                    <div className={'rsbcText flex items-center justify-center'}>{this.getText()}{this.props.showReset && <span onClick={()=>this.reset()}  style={{cursor: 'pointer', marginLeft: '5px', textDecoration: 'underline'}}>Reset</span>}</div>
                </div>
            </div>
        )
    }
}