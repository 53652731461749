
export const Bekko = () => {
    return (
        <div className="grid grid-cols-12 gap-2 p-3">
            <div className="col-span-12 sm:col-span-5 pt-3">
                <p className="mb-2"><strong>White, red, or yellow koi with black patches</strong></p>
                <p>{`The Bekko is a solid colored non-metallic koi fish with black (sumi) patches on the body. Bekko koi are produced in three colors; white, red and yellow. The white variety is called a Shiro Bekko. It is a clean white koi fish with the addition of black patches. The Aka Bekko is a red or orange koi with black spots, and the Ki Bekko is a yellow koi fish with black patches.`}</p>
                <p>{`Sumi placement is important when evaluating a Bekko koi fish. The sumi patches on a Bekko should appear uniformly on the Koi's back. They should preferably be located above the lateral line, and never ahead of the shoulder region. The head of the Bekko should be free of any black dots or patches.`}</p>
                <p>{`The fins of Bekko koi are generally white and may have intermittent sumi stripes which often help to maintain balance of the sumi pattern.`}</p>
                <p>{`The Bekko is generally one of the first varieties that a beginner koi hobbyist learns to recognize and is a very popular fish for Japanese koi ponds and water gardens.`}</p>
            </div>

            <div className="col-span-12 sm:col-span-7 flex justify-center pt-2 flex-wrap">
                <div className="text-center">
                    <img src="/full/306.png" width={200} />
                    <p>Shiro Bekko</p>
                    <p className="text-secondary" style={{ fontSize: '12px' }}>Common scales</p>
                </div>
                <div className="text-center">
                    <img src="/full/302.png" width={200} />
                    <p>Aka Bekko</p>
                    <p className="text-secondary" style={{ fontSize: '12px' }}>Common scales</p>
                </div>
                <div className="text-center">
                    <img src="/full/301.png" width={200} />
                    <p>Ki Bekko</p>
                    <p className="text-secondary" style={{ fontSize: '12px' }}>Doitsu scales</p>
                </div>

            </div>
        </div>
    );
}