import _ from 'lodash'
import { ContentLayout } from "components/Layout.tsx/ContentLayout";
import Highlighter from 'react-highlight-words'
import glossary from 'data/glossary.json'
import { useState } from "react";
import { Badge } from "components/common/Badge";
import Table from 'components/html/Table';
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine } from '@remixicon/react';

let allCategories: any[] = []
const items = glossary.map(item => {

    const { category, ...rest } = item
    const categories = category.split(' ').filter(c => c !== '')
    allCategories = _.uniq([...allCategories, ...categories])
    return { ...rest, categories }
})



export type Koi = {
    id: number;
    num: number;
    gender: string;
    klass: string;
}


const badgeColor: { [key: string]: string } = {
    body: 'bg-indigo-100',
    sumi: 'bg-gray-100',
    hi: 'bg-red-100',
    color: 'bg-yellow-100',
    koi: 'bg-green-100',
    misc: 'bg-purple-100',
    'kiwa-sashi': 'bg-blue-100',
    property: 'bg-purple-100',
    ginrin: 'bg-blue-100',
    pattern: 'bg-blue-100',
    scale: 'bg-blue-100',
    head: 'bg-indigo-100',
    shiroji: 'bg-pink-100',
}


export const GlossaryPage = () => {

    const [searchQuery, setSearchQuery] = useState<string>('')
    const [selectedCategories, setSelectedCategories] = useState<any[]>(allCategories)

    const toggleCategory = (category: string) => {
        if (selectedCategories.includes(category)) {
            // console.log(selectedCategories.filter(c => c !== category))
            setSelectedCategories(selectedCategories.filter(c => c !== category))
        } else {
            setSelectedCategories([...selectedCategories, category])
        }
    }

    const filter = (item: any) => {

        let conditions = [false, false]
        if (item.description.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 || item.keyword.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1) {
            conditions[0] = true
        }

        if (selectedCategories.length === 13) {
            conditions[1] = true
        } else {
            for (let selectedCategory of selectedCategories) {
                if (item.categories.includes(selectedCategory)) {
                    conditions[1] = true
                    break
                }
            }
        }

        return conditions[0] && conditions[1]
    }

    return (
        <ContentLayout heading="Glossary">
            <div className='space-y-4'>

                {/* register your input into the hook by invoking the "register" function */}

                <input
                    className="input input-bordered w-full"
                    placeholder='Search...'
                    type='search'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setSearchQuery(event.target.value as string)
                    }}
                />

                <div className='mt-3 flex items-center flex-wrap gap-1'>
                    <button
                        className='btn btn-xs'
                        style={{ marginRight: '5px' }}
                        onClick={() => setSelectedCategories(allCategories)}
                    >
                        <span>Select all</span>
                    </button>
                    <button
                        className='btn btn-xs'
                        style={{ marginRight: '5px' }}
                        onClick={() => setSelectedCategories([])}
                    >
                        <span>Deselect all</span>
                    </button>
                </div>
                <div className='flex items-center flex-wrap gap-1'>
                    {allCategories.sort().map((c: string) => {
                        return <button
                            key={c}
                            className='btn btn-xs'
                            style={{ marginRight: '5px' }}
                            onClick={() => toggleCategory(c)}
                        >
                            {selectedCategories.includes(c) && <RiCheckboxCircleLine size={16} className='mr-1'/>}
                            {!selectedCategories.includes(c) && <RiCheckboxBlankCircleLine size={16} className='mr-1'/>}
                            <span>{c}</span>
                        </button>
                    })}
                </div>

                <Table.table>
                    <Table.thead>
                        <Table.row>
                            <Table.th first>
                                Words
                            </Table.th>
                            <Table.th last>
                                Description
                            </Table.th>
                        </Table.row>
                    </Table.thead>
                    <Table.tbody>
                        {items.filter(filter).map((item: any) => {
                            return <Table.row key={item.keyword}>
                                <Table.td>
                                    <p className="mb-0"><Highlighter highlightStyle={{ backgroundColor: '#fdff32' }} searchWords={[searchQuery]} autoEscape={true} textToHighlight={item.keyword} /></p>
                                    <p className="mb-0 text-secondary" style={{ fontSize: '10px' }}>{item.sound}</p>
                                    <div className='space-x-1'>
                                        {item.categories.map((category: any) => {
                                            return <Badge key={category} bg={badgeColor[category]}>{category}</Badge>
                                        })}
                                    </div>

                                </Table.td>
                                <Table.td last><Highlighter highlightStyle={{ backgroundColor: '#fdff32' }} searchWords={[searchQuery]} autoEscape={true} textToHighlight={item.description} /></Table.td>
                            </Table.row>
                        })}
                    </Table.tbody>
                </Table.table>

            </div>
        </ContentLayout>
    );
}