
export const Showa = () => {
    return (
        <div className="grid grid-cols-12 gap-2 p-3">
            <div className="col-span-12 sm:col-span-6 pt-3">
                <p className="mb-2"><strong>Black koi with red a pattern and white markings</strong></p>

                <p>{`Showa is another very popular koi variety for Japanese koi ponds and water gardens.`}</p>
                <p>{`Showa koi have red markings and white patterns formed on the black background, whereas Sanke koi fish have red markings and black patches on a white background. Showa have much more sumi than the Sanke, and it is very different from the sumi of a Sanke. While the Sanke sumi looks like western oil paintings, the Showa sumi carries the tone of oriental black-and-white paintings made with ink.`}</p>
                <p>{`Many Showa koi may have sumi appearing at the base of their pectoral fins, called motogoro. The sumi of Showa seems to be all connected below the surface and encompassing the body. Consequently, Showa appears quite magnificent.`}</p>
                <p>{`A good quality Showa has a pleasing red Kohaku pattern and the Shiroji(white markings) should be clean and clear, actually appearing bright in contrast to its other colors. The sumi should form black bands which wrap around the body and face as seen on the Shiro Utsuri variety.`}</p>
            </div>

            <div className="col-span-12 sm:col-span-6 flex justify-center pt-2 flex-wrap">
                <div className="text-center">
                    <img src="/full/1229.png" width={200} />
                    <p className="m-0">Showa</p>
                    <p className="text-secondary" style={{ fontSize: '12px' }}>Common scales</p>
                </div>
                <div className="text-center">
                    <img src="/full/1237.png" width={200} />
                    <p className="m-0">Showa</p>
                    <p className="text-secondary" style={{ fontSize: '12px' }}>Doitsu scales</p>
                </div>

            </div>
        </div>
    );
}