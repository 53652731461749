import { Footer } from "components/common/Footer"
import { LightBox } from "components/common/LightBox"
import Navbar from "components/Navbar";
import { ReactNode } from "react"

type Props = {
    heading: string | ReactNode;
    children: ReactNode;
}

export const TransparentLayout = (props: Props) => {

    const { heading, children } = props

    return (<>
        <div className='relative'>
            <Navbar />
        </div>
        <div className="py-1 my-1 mx-auto px-2 sm:px-4 lg:px-8 max-w-7xl space-y-4">
            <div className="space-y-2">
                <LightBox className='p-2 sm:p-4 '>
                    <h2 className="text-xl sm:text-2xl font-bold">{heading}</h2>
                </LightBox>

                <div>
                    {children}
                </div>

            </div>
            <Footer />
        </div>
    </>)
}