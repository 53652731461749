
export const Asagi = () => {
    return (
        <div className="grid grid-cols-12 gap-2 p-3">
            <div className="col-span-12 sm:col-span-4 md:col-span-3 pt-3">
                <p className="mb-2"><strong>One of the original varieties of Nishikigoi</strong></p>

                <p>{`Asagi are fairly classical from a genealogical point of view, and constitute a very tasteful variety. They usually have blue on their entire back and red on the belly, pectoral fins and gill covers.`}</p>
                <p>{`The scales on the back have whitish base and thus collectively give an appearance of meshes of a net.`}</p>
                <p>{`The important viewing points are the conspicuously vivid appearance of the meshes and light blue, spotless head region.`}</p>

            </div>

            <div className="col-span-12 sm:col-span-8 md:col-span-9 flex justify-center pt-2 flex-wrap">
                <div className="text-center">
                    <img src="/full/1541.png" width={200} />
                    <p>Mizu Asagi</p>
                </div>
                <div className="text-center">
                    <img src="/full/1785.png" width={200} />
                    <p>Konjo Asagi</p>
                </div>
                <div className="text-center">
                    <img src="/full/1530.png" width={200} />
                    <p>Narumi Asagi</p>
                </div>
                <div className="text-center">
                    <img src="/full/1598.png" width={200} />
                    <p>Hi Asagi</p>
                </div>
            </div>
        </div>
    );
}