import { RiFileCopy2Line } from "@remixicon/react";
import copy from "copy-to-clipboard"
import { toast } from "helpers";

type Props = {
    value: string;
    color: string;
    size: number;
}

const CopyButton = (props: Props) => {
    const { value, color, size } = props
    return <button onClick={() => {
        copy(value)
        toast('success', 'Copied to clipboard')
    }} className="btn btn-sm btn-square">
        <RiFileCopy2Line size={size} color={color} />
    </button>
}

export { CopyButton }