import ListItem from "./ListItem"

type Props = {
    items: any[];
    size: 'small' | 'full';
    onSelect: (koi: any) => void;
    percentage: number;
}

const ListItems = (props: Props) => {
    const { items, size, onSelect, percentage } = props
    return <>
        {items.map(koi => {
            return <ListItem size={size} key={koi.num} clicked={(koi) => {
                // if (outerWidth && outerWidth >= 768) {
                onSelect(koi)
                // }
            }} koi={koi} percentage={percentage} />
        })
        }</>
}

export default ListItems