import Loading from "components/common/Loading";
import { ContentLayout } from "components/Layout.tsx/ContentLayout";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useWindowSize } from "rooks"
import data from 'data/data.json'
import ListItems from "components/common/ListItems";
import { Box } from "components/common/Box";
import { classNames } from "helpers";
import { useNftDropsContext } from "context/NftDropsContext";
import { Modal } from "components/common/Modal";

const kois = data.kois

export type Koi = {
    id: number;
    num: number;
    gender: string;
    klass: string;
}

export const NFTPage = () => {

    const { searchQuery } = useNftDropsContext()
    const { outerWidth } = useWindowSize();
    const [percentage, setPercentage] = useState<number>(100)
    const [imageSize, setImageSize] = useState<'small' | 'full'>()
    const [items, setItems] = useState<Koi[]>(kois)
    const [currentKoi, setCurrentKoi] = useState<Koi>()
    const [showModal, setShowModal] = useState<boolean>(false)

    const [currentItems, setCurrentItems] = useState<Koi[]>(kois.slice(0, 90))
    const [hasMore, setHasMore] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)


    useEffect(() => {
        const q = (searchQuery === null ? '' : searchQuery) as string
        if (q.length > 0) {
            const _kois = kois.filter(koi => {
                // let num = koi.num + ''
                return koi.klass.toLowerCase().indexOf(q.toLowerCase()) > -1 // || num.indexOf(q) > -1 
            })
            setItems(_kois)
            setHasMore(true)
        } else {
            setItems(kois)
            setHasMore(true)
        }
        setLoading(false)
    }, [searchQuery])

    useEffect(() => {
        setCurrentItems(items.slice(0, 90))
    }, [items])

    useEffect(() => {
        const _width = outerWidth || 1500
        if (_width >= 1024) {
            setPercentage(100 / 9)
            setImageSize('small')
        } else if (_width >= 768) {
            setPercentage(100 / 5)
            setImageSize('small')
        } else {
            setPercentage(50)
            setImageSize('full')
        }

    }, [outerWidth])

    const handleClose = () => {
        setShowModal(false)
    }

    const fetchMoreData = () => {
        if (currentItems.length >= items.length) {
            setHasMore(false);
            return;
        }
        // a fake async api call like which sends
        // 20 more records in .5 secs
        setTimeout(() => {
            setCurrentItems([...currentItems, ...items.slice(currentItems.length, currentItems.length + 45)])
        }, 500);
    };


    return (<>
        {/* 
        <div className={classNames(
            `modal modal-bottom sm:modal-middle`,
            currentKoi ? 'modal-open' : ''
        )}>
            <div className="modal-box relative overflow-hidden">
                <label onClick={() => handleClose()} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                <div style={{ backgroundColor: '#ADD8E6' }}>
                    {currentKoi && <img src={`/full-bg/${currentKoi?.id}.png`} width="100%" />}
                </div>
            </div>
        </div> */}

        <Modal
            size="lg"
            show={showModal}
            onClose={handleClose}
        >
            <div style={{ backgroundColor: '#ADD8E6' }}>
                {currentKoi && <img src={`/full-bg/${currentKoi?.id}.png`} />}
            </div>
        </Modal>

        <ContentLayout heading="NFT">
            <div>
                <p className="mb-5">
                    When purchasing a koi NFT, the system selects a random koi to ensure a fair distribution and to bring a level of excitement. The 1st drop has been released and the koi NFTs are available to purchase now on this site.
                </p>
            </div>
            <Box>
                <div>
                    {loading && <Loading />}
                    {!loading && <InfiniteScroll
                        className="flex flex-row flex-wrap"
                        style={{ backgroundColor: 'ADD8E6' }}
                        dataLength={currentItems.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<Loading />}
                    >

                        {imageSize && <ListItems
                            items={currentItems}
                            size={imageSize}
                            percentage={percentage}
                            onSelect={(koi: any) => {
                                setCurrentKoi(koi)
                                setShowModal(true)
                            }}
                        />}



                    </InfiniteScroll>}

                </div>
            </Box>


        </ContentLayout >
    </>


    );
}