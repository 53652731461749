
export const Goshiki = () => {
    return (
        <div className="grid grid-cols-12 gap-2 p-3">
            <div className="col-span-12 sm:col-span-6 pt-3">

                <p className="mb-2"><strong>White, red, black, blue and dark blue koi</strong></p>

                <p>{`Goshiki koi have been called peacock koi. The term peacock koi refers to the five colors on the body of the koi. The base color of this variety is white and the fins are generally white and free of any pigment. The dorsal pattern on Goshiki is red. The scales develop blue colors and a black outlining, or reticulation (net pattern).`}</p>
                <p>{`A high quality Goshiki koi should have a well-balanced Kohaku pattern of red, as well as gray or blue scales with consistent reticulation on all of its scales.`}</p>
                <p>{`Goshiki used to be included in the Kawarimono group. However, with recent production of fairly excellent Goshiki, they are now being treated as an independent variety at Nishikigoi shows, and sometimes compete along with the Koromo group.`}</p>
                <p>{`Old Style Goshiki: An Old Style Goshiki has heavy and dark reticulation on its scales, and it may also appear on the scales of the red pattern.`}</p>
                <p>{`New Style Goshiki: The New Style Goshiki have predominantly more white base showing with light gray reticulation on the scales, and little or no reticulation on the red pattern. It will have a bright almost fluorescent hi pattern.`}</p>
            </div>

            <div className="col-span-12 sm:col-span-6 flex justify-center pt-2 flex-wrap">
                <div className="text-center">
                    <img src="/full/2104.png" width={200} />
                    <p className="m-0">Goshiki</p>
                    <p className="text-secondary" style={{ fontSize: '12px' }}>Common scales</p>
                </div>
                <div className="text-center">
                    <img src="/full/2106.png" width={200} />
                    <p className="m-0">Goshiki</p>
                    <p className="text-secondary" style={{ fontSize: '12px' }}>Doitsu scales</p>
                </div>

            </div>
        </div>
    );
}