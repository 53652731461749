type Props = {
    children: string;
}
export default function H1(props: Props) {
    const { children } = props
    return (
        <h1 className="text-xl sm:text-3xl mb-4">
            {children}
        </h1>
    )
}