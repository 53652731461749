import { Box } from "components/common/Box";
import Divider from "components/common/Divider";
import Table from "components/html/Table";
import { ContentLayout } from "components/Layout.tsx/ContentLayout";

const kois = [
    { num: 10, klass: 'Kohaku' },
    { num: 301, klass: 'Bekko' },
    { num: 601, klass: 'Utsuri' },
    { num: 901, klass: 'Sanke' },
    { num: 1211, klass: 'Showa' },
    { num: 1501, klass: 'Asagi' },
    { num: 1821, klass: 'Shusui' },
    { num: 2102, klass: 'Goshiki' },
    { num: 2403, klass: 'Koromo' },
]


export const HomePage = () => {
    return (
        <ContentLayout heading="Welcome to POK Business!">
            <div className="space-y-4">

                <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12 sm:col-span-8">
                        <div className='space-y-4'>
                            <p>POK Business is a next generation blockchain game that resembles the koi hobby. It allows users to own, manage and interact with their koi NFTs.</p>
                            <p>POK Business will be releasing a total of 6000 unique koi NFTs of 16 various types in three drops:</p>

                            <Table.table>
                                <Table.thead>
                                    <Table.row>
                                        <Table.th first>
                                            #
                                        </Table.th>
                                        <Table.th >
                                            Total drop
                                        </Table.th>
                                        <Table.th >
                                            Types
                                        </Table.th>
                                        <Table.th last>
                                            Discount
                                        </Table.th>
                                    </Table.row>
                                </Table.thead>
                                <Table.tbody>
                                    <Table.row>
                                        <Table.td first>
                                            1st drop
                                        </Table.td>
                                        <Table.td>
                                            2700
                                        </Table.td>
                                        <Table.td>
                                            9 <span className="hidden sm:inline">various </span>types
                                        </Table.td>
                                        <Table.td last>
                                            65%
                                        </Table.td>

                                    </Table.row>
                                    <Table.row>
                                        <Table.td first>
                                            2nd drop
                                        </Table.td>
                                        <Table.td>
                                            2100
                                        </Table.td>
                                        <Table.td>
                                            7 <span className="hidden sm:inline">various </span>types
                                        </Table.td>
                                        <Table.td last>
                                            40%
                                        </Table.td>
                                    </Table.row>
                                    <Table.row>

                                        <Table.td first>
                                            3rd drop
                                        </Table.td>
                                        <Table.td>
                                            1200
                                        </Table.td>
                                        <Table.td>
                                            All 16 types
                                        </Table.td>
                                        <Table.td last>
                                            -
                                        </Table.td>
                                    </Table.row>
                                </Table.tbody>
                            </Table.table>

                            <Box>
                                <div className="flex justify-center flex-wrap py-5">
                                    {kois.map(koi => {
                                        return <div key={koi.num} className="text-center">
                                            <img src={`/small/${koi.num}.png`} width={80} />
                                            <p>{koi.klass}</p>
                                        </div>
                                    })}
                                </div>
                            </Box>

                            <p className="text-secondary text-center"><small> 1st drop koi classes </small></p>
                            <p>When purchasing a koi NFT, the system selects a random koi to ensure a fair distribution and to bring a level of excitement. The 1st drop has been released and the koi NFTs are available to purchase now on this site.</p>
                            <p> <strong>The game will feature:</strong></p>
                            <ul>
                                <li>Breeding which allows users to create and own new beautiful koi fish.</li>
                                <li>Koi beauty contest, allowing users to participate using their own koi.</li>
                                <li>Pond management; a healthy pond means a happy koi.</li>

                            </ul>
                            <p>With more details to follow. Once the 3rd drop has been released the breeding feature will be unlocked.</p>
                            <p>POK Business uses Bitcoin SV, the original bitcoin as the backbone technology which is a fast, cheap and a reliable blockchain, enabling users to have a seamless experience playing the game or trading their koi NFTS.</p>
                            <p><strong>Be sure to participate early to get a good discount!</strong></p>
                        </div>
                    </div>
                    <div className="col-span-12 sm:col-span-4">
                        <video style={{ border: '1px solid #000' }} width={'100%'} controls poster="/video-poster.jpg">
                            <source src={`${process.env.REACT_APP_CDN_ENDPOINT}/Common/assets/ads.mp4`} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>

                <Divider />
                <div>
                    <iframe width={'100%'} height={450} src="https://cdn.forms-content.sg-form.com/55fae5d1-4fa1-11ec-adf6-1682df6d0410" />
                </div>
            </div>

        </ContentLayout >
    );
}