import axios, { AxiosResponse } from 'axios';
import { P2PKHAddress } from 'bsv-wasm-web';

// Define interfaces for UTXO and WocUtxo
export interface UTXO {
    satoshis: number;
    vout: number;
    txid: string;
    script: string;
}

export interface WocUtxo {
    height: number;
    tx_pos: number;
    tx_hash: string;
    value: number;
}

interface FetchUtxosResponse {
    utxos: UTXO[]
}

const network = axios.create({
    baseURL: 'https://api.whatsonchain.com/v1/bsv/main',
});

// Function to fetch UTXOs for a given address
const fetchUtxos = async (address: string): Promise<FetchUtxosResponse> => {
    try {
        // Fetch UTXOs from the API
        const { data }: AxiosResponse<WocUtxo[]> = await network.get(`/address/${address}/unspent`);

        // Convert fetched UTXOs to the desired format
        const utxos: UTXO[] = data
            // Ensure we are never spending 1 satoshis
            .filter((utxo: WocUtxo) => utxo.value !== 1)
            // Map each UTXO to the UTXO interface
            .map((utxo: WocUtxo) => ({
                satoshis: utxo.value,
                vout: utxo.tx_pos,
                txid: utxo.tx_hash,
                script: P2PKHAddress.from_string(address).get_locking_script().to_hex(),
            }));

        return { utxos };
    } catch (error) {
        // {"message": "unexpected response code 500: 257: txn-already-known"} # in block chain already
        // {"message": "unexpected response code 500: Transaction already in the mempool"} # in mempool
        console.error('Error fetching UTXOs from WhatsOnChain:', error);
        throw error;
    }
};

export default { fetchUtxos }