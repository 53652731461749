import { DropShadowFilter } from 'pixi-filters';
import { Container, Graphics, Point, SimpleRope, Sprite, Text, TextStyle, Texture } from 'pixi.js'
import { degreesToRadians } from '../helpers';
import { InfoText } from './InfoText';




export class Flagellum extends Container {

    count: number = 0;
    texture: Texture;
    numNodes = 16

    // slow 0.2 2, normal 0.4, 4, fast 0.6, 6, very fast 0.8 8
    muscleFreq = 0.06 // ความถี่รอบ
    muscleRange = 6// 4-8 วงสวิง
    theta = 180
    ropeLength: number
    points: Point[] = []
    rope: SimpleRope;
    g: Graphics;
    infoText: InfoText;

    constructor(id: number, scale: number) {
        super()

        this.texture = Texture.from(`koi_${id}`)

        this.ropeLength = (this.texture.width) / this.numNodes

        for (let i = 0; i < this.numNodes; i++) {
            this.points.push(new Point(i * this.ropeLength, 0));
        }

        this.rope = new SimpleRope(this.texture, this.points);


        this.g = new Graphics();
        this.g.x = this.rope.x;
        this.g.y = this.rope.y;

        // this.strip.x = 0;

        // this.x = 400;
        // this.y = 300;
        this.scale.set(scale);

        this.addChild(this.rope)
        this.addChild(this.g)


        this.infoText = new InfoText(`x: ${this.x}, y: ${this.y}`)

        // this.addChild(this.infoText)

        const dropShadowFilter = new DropShadowFilter({
            blur: 10,
            quality: 3,
            alpha: 0.3,
            distance: 30,
            rotation: 45
        })

        this.filters = [dropShadowFilter]

    }

    public update(framesPassed: number): void {

        this.points[0].x = Math.cos(degreesToRadians(this.theta))
        this.points[0].y = Math.sin(degreesToRadians(this.theta))

        this.count += this.muscleFreq;
        const thetaMuscle = this.muscleRange * Math.sin(this.count)

        this.points[1].x = -this.ropeLength * Math.cos(degreesToRadians(this.theta + thetaMuscle)) + this.points[0].x
        this.points[1].y = -this.ropeLength * Math.sin(degreesToRadians(this.theta + thetaMuscle)) + this.points[0].y

        for (let n = 2; n < this.points.length; n++) {

            let dx = this.points[n].x - this.points[n - 2].x
            let dy = this.points[n].y - this.points[n - 2].y
            let d = Math.sqrt(dx * dx + dy * dy)

            this.points[n].x = this.points[n - 1].x + ((dx * this.ropeLength) / d)
            this.points[n].y = this.points[n - 1].y + ((dy * this.ropeLength) / d)

        }

        // this.renderPoints()

    }

    renderPoints() {
        this.g.clear();

        this.g.lineStyle(2, 0xffc2c2);


        // this.g.moveTo(this.x, this.y)
        // this.g.lineTo(this.x + this.width, this.y);
        // this.g.lineTo(this.x + this.width, this.y + this.height);
        // this.g.lineTo(this.x, this.y + this.height);
        // this.g.lineTo(this.x, this.y);



        this.g.moveTo(this.points[0].x, this.points[0].y);

        for (let i = 1; i < this.points.length; i++) {
            this.g.lineTo(this.points[i].x, this.points[i].y);
        }

        for (let i = 1; i < this.points.length; i++) {
            this.g.beginFill(0xff0022);
            this.g.drawCircle(this.points[i].x, this.points[i].y, 10);
            this.g.endFill();
        }



    }




    public setMuscleFreq(muscleFreq: number) {
        this.muscleFreq = muscleFreq
    }

    public setTheta(theta: number) {
        this.theta = theta
    }


}

