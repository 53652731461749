import { LightBox } from "./LightBox"
import _ from "lodash";
import { useEffect, useState } from "react";
import { useAppContext } from "context/AppContext";
import { getCdnEndpointByEnv } from "helpers";
import { RiErrorWarningLine, RiGitRepositoryFill, RiMenLine, RiWomenLine } from "@remixicon/react";
import { useModal } from "context/ModalContext";

type Props = {
    item: any;
    onSelect: (item: any) => void;
    onSend?: (item: any) => void;
    onBuy?: (item: any) => void;
}
const KoiMiniCard = (props: Props) => {

    const { item, onSelect, onSend, onBuy } = props

    const { authenticated, currentUser } = useAppContext()
    const { showModal } = useModal()

    const [gender, setGender] = useState<string>()
    const [classification, setClassification] = useState<string>()

    useEffect(() => {

        const traits = item.origin.data.map.subTypeData.traits

        const _gender = traits.find((trait: any) => trait.name === 'gender').value
        const _classification = traits.find((trait: any) => trait.name === 'classification').value

        setGender(_gender)
        setClassification(_classification)

    }, [item])

    return <div className='col-span-12 sm:col-span-6 xl:col-span-4'>
        <LightBox className="my-nft p-2">
            <div className="flex items-center" >
                <img style={{ cursor: 'pointer' }} onClick={() => {
                    onSelect(item)
                }} width="100" height="100" src={`${getCdnEndpointByEnv()}/inscriptions/koi/preview/${item.origin.outpoint}.png`} />

                <div className="flex-1 px-3">
                    <p className="text-sm mb-0"><strong>{item.origin.data.map.name}</strong></p>

                    <div className="flex items-center">
                        <RiGitRepositoryFill size={15} className="mr-1" />
                        <p style={{ marginRight: '10px' }} className="mb-0"><small>{classification}</small></p>
                        {gender === 'Male' && <RiMenLine size={15} className='mr-1 text-secondary' />}
                        {gender === 'Female' && <RiWomenLine size={15} className='mr-1 text-error' />}
                        <p className="mb-0"><small>{gender}</small></p>
                    </div>

                    {item.data?.list && <div className="flex items-center space-x-2 mt-2">
                        <img src={'/logo/bsv.png'} width={20} height={20} />
                        <span className="text-sm text-gray-600 price-tag">{item.data?.list.price / 100000000}</span>
                    </div>}
                </div>

                {currentUser && currentUser.ordAddress === item.owner && !item.data?.list && <div onClick={() => {
                    typeof onSend === 'function' && onSend(item)
                }} className="send-button"><strong>SEND</strong></div>}
                {currentUser && currentUser.ordAddress !== item.owner && item.data?.list && <div onClick={() => {
                    if (authenticated) {
                        typeof onBuy === 'function' && onBuy(item)
                    } else {
                        showModal(<span className="flex items-center space-x-2 mb-2">
                            <RiErrorWarningLine className="h-7 w-7" />
                            <span>Login Required</span>
                        </span>, <div style={{ width: '100%' }}>
                            <p>To proceed with your purchase, you need to be logged in. Please log in to continue or sign up if you don't have an account yet.</p>
                        </div>, 'md')
                    }
                }} className="send-button"><strong>BUY</strong></div>}
            </div>
        </LightBox>

    </div>
}

export { KoiMiniCard }