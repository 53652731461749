import { Footer } from "components/common/Footer"
import { LightBox } from "components/common/LightBox"
import { classNames } from "helpers";
import { ReactNode, useState } from "react"
import { Link } from "react-router-dom";
import Divider from "components/common/Divider";

type Props = {
    heading: string;
    children: ReactNode;
}

type MenuItem = {
    name: string;
    href: string;
    icon: [any, any];
    current: boolean;
}

const navigation: {
    name: string;
    href: string;
    icon: [any, any];
    current: boolean;
}[] = [

    ]

export const GamingLayout = (props: Props) => {

    const { heading, children } = props

    const [sidebarOpen, setSidebarOpen] = useState(false)


    const renderMenu = (item: MenuItem) => {
        return <a
            key={item.name}
            href={item.href}
            className={classNames(
                item.current ? 'bg-base-100/50 text-gray-900' : 'text-gray-600 hover:bg-base-100/25 hover:text-gray-900',
                'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
            )}
        >
            <div className="flex w-8 justify-center">
                {/* <FontAwesomeIcon size='lg' className=" text-neutral mr-3" icon={item.icon} /> */}

            </div>
            {item.name}
        </a>
    }

    const renderDivider = () => {
        return <div className="border-base-100 border-opacity-40 border-t"></div>
    }

    return (<>

        {/* <div className="drawer drawer-mobile">
            <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
             <div className="drawer-content flex flex-col items-center justify-center">
                <label htmlFor="my-drawer-2" className="btn btn-primary drawer-button lg:hidden">Open drawer</label>
            </div> 
            <div className="drawer-side">
                <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
                <ul className={classNames(
                    `menu m-4 p-4 overflow-y-auto w-80 text-base-content`,
                    `backdrop-blur-sm bg-base-100/20 border border-white border-opacity-40 shadow-2xl rounded-lg`
                )}>
                    <li><a>Sidebar Item 1</a></li>
                    <li><a>Switch to website</a></li>
                </ul>

            </div>
        </div> */}


        <div className="relative hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 ">
            <div className="transform scale-y-150 translate-x-1/4 absolute w-full h-full bg-gradient-to-r from-base-100/25 to-base-100/75"></div>
            {/* <div className="absolute w-32 ml-64">
                <div className="relative">
                    <div className="ring ring-white backdrop-blur-sm bg-gradient-to-r from-base-100/40 to-base-100/60  shadow-2xl absolute z-0 left-5 top-5 w-20 h-20 rounded-full"></div>
                    <img className="relative z-5 rotate-45" src='/actions/net.png' />
                </div>
                <div className="relative -mt-5">
                    <div className="ring ring-white backdrop-blur-sm bg-gradient-to-r from-base-100/40 to-base-100/60 shadow-2xl absolute z-0 left-5 top-5 w-20 h-20 rounded-full"></div>
                    <div className="ml-3 pt-1">
                        <img className="relative z-5" width='100px' src='/actions/food.png' />
                    </div>
                </div>
            </div> */}
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className={
                classNames(
                    'relative',
                    `flex-1 flex flex-col min-h-0`,
                    `border border-r border-base-100 border-opacity-40 shadow-2xl`
                )
            }>
                <div className="flex-1 flex flex-col overflow-y-auto">
                    <div className="relative backdrop-blur-sm bg-gradient-to-r from-base-100/40 to-base-100/60 flex items-center flex-shrink-0">
                        <div className="px-4 py-5">
                            <Link to='/' className="flex items-center logo">
                                <img
                                    style={{ marginRight: '10px' }}
                                    src="/logo.jpeg"
                                    width="35"
                                    height="35"
                                    className='rounded-full drop-shadow-lg shadow-black' />
                                <p className="drop-shadow-md text-2xl">POK Business</p>
                            </Link>
                        </div>
                    </div>
                    <nav className="backdrop-blur-sm bg-base-100/75 flex-1 space-y-2">
                        {renderDivider()}
                        <div className="px-2 space-y-1">
                            {renderMenu({ name: 'Pond', href: '#', icon: ['fas', 'fish-fins'], current: true })}
                            {renderMenu({ name: 'Marketplace', href: '#', icon: ['fas', 'shop'], current: false })}
                        </div>
                        {renderDivider()}
                        <div className="px-2 space-y-1">
                            {renderMenu({ name: 'Schedule', href: '#', icon: ['fas', 'calendar'], current: false })}
                            {renderMenu({ name: 'Contests', href: '#', icon: ['fas', 'trophy'], current: false })}
                        </div>
                        {renderDivider()}
                        <div className="px-2 space-y-1">
                            {renderMenu({ name: 'Search', href: '#', icon: ['fas', 'search'], current: false })}
                            {renderMenu({ name: 'Tranfers', href: '#', icon: ['fas', 'sync'], current: false })}
                            {renderMenu({ name: 'Farm info', href: '#', icon: ['fas', 'people-roof'], current: false })}
                        </div>
                        {renderDivider()}
                        <div className="px-2 space-y-1">
                            {renderMenu({ name: 'Finances', href: '#', icon: ['fas', 'coins'], current: false })}
                            {renderMenu({ name: 'Settings', href: '#', icon: ['fas', 'cog'], current: false })}
                        </div>
                        {/* {navigation.map((item) => (
                            <a
                                key={item.name}
                                href={item.href}
                                className={classNames(
                                    item.current ? 'bg-base-100/50 text-gray-900' : 'text-gray-600 hover:bg-base-100/25 hover:text-gray-900',
                                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                )}
                            >
                                <div className="flex w-8 justify-center">
                                    <FontAwesomeIcon size='lg' className=" text-neutral mr-3" icon={item.icon} />

                                </div>
                                
                                {item.name}
                            </a>
                            <Divider />
                        ))} */}
                    </nav>
                </div>
                <div className="backdrop-blur-sm bg-gradient-to-r from-base-100/40 to-base-100/60 pb-4 flex-shrink-0 flex border-t border-gray-200 p-4">
                    <a href="#" className="flex-shrink-0 w-full group block">
                        <div className="flex items-center">
                            <div>
                                <img
                                    className="inline-block h-9 w-9 rounded-full"
                                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                    alt=""
                                />
                            </div>
                            <div className="ml-3">
                                <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">Tom Cook</p>
                                <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">View profile</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div className="md:pl-64 flex flex-col flex-1">
            <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-white">
                <button
                    type="button"
                    className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    onClick={() => setSidebarOpen(true)}
                >
                    <span className="sr-only">Open sidebar</span>
                    {/* <Bars className="h-6 w-6" aria-hidden="true" /> */}
                </button>
            </div>
            <main className="flex-1">
                <div className="py-6">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                        <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
                    </div>
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                        {/* Replace with your content */}
                        <div className="py-4">
                            <div className="border-4 border-dashed border-gray-200 rounded-lg h-96" />
                        </div>
                        {/* /End replace */}
                    </div>
                </div>
            </main>
        </div>




        {/* <div className="py-1 my-1 mx-auto px-2 sm:px-4 lg:px-8 max-w-7xl space-y-4">
            <div className="space-y-2">
                <LightBox className='p-2 sm:p-4 '>
                    <h2 className="text-xl sm:text-2xl font-bold">{heading}</h2>
                </LightBox>

                <div>
                    {children}
                </div>

            </div>
            <Footer />
        </div> */}
    </>)
}