
export const Utsuri = () => {
    return (
        <div className="grid grid-cols-12 gap-2 p-3">
            <div className="col-span-12 sm:col-span-5 pt-3">
                <p className="mb-2"><strong>Black koi with white, red or yellow markings</strong></p>

                <p>{'Utsuri is another very popular koi fish for Japanese koi ponds and water gardens. Good quality Utsuri is known for having pleasing checkerboard patterns and clear defined colors.'}</p>
                <p>{`The Utsuri have black skin and are divided by interlacing markings of one other non-metallic color.`}</p>
                <p>{`A Utsuri with red markings is called Hi Utsuri, white marking is called Shiro Utsuri and the yellow markings is called Ki Utsuri.`}</p>
                <p>{`The sumi should preferably be dark and distinct on the koi regardless of the contrasting color. Like the Showa variety, sumi (black) on an Utsuri should essentially appear as bands of color on the nose, face side and throughout the body, as well as on the base of their pectoral fins (Motogoro).`}</p>
                <p>{`Motogoro is the term for the black pigments located on the base of their pectoral fins. Menware is the term referring to a band of sumi which divides the face or head pattern on the koi. The pectoral fins of Hi Utsuri and Ki Utsuri do not show Motoguro, instead they are striped with sumi.`}</p>
            </div>

            <div className="col-span-12 sm:col-span-7 flex justify-center pt-2 flex-wrap">
                <div className="text-center">
                    <img src="/full/601.png" width={200} />
                    <p>Shiro Utusuri</p>
                    <p className="text-secondary" style={{ fontSize: '12px' }}>Common scales</p>
                </div>
                <div className="text-center">
                    <img src="/full/602.png" width={200} />
                    <p>Hi Utusuri</p>
                    <p className="text-secondary" style={{ fontSize: '12px' }}>Common scales</p>

                </div>
                <div className="text-center">
                    <img src="/full/604.png" width={200} />
                    <p>Ki Utusuri</p>
                    <p className="text-secondary" style={{ fontSize: '12px' }}>Doitsu scales</p>
                </div>

            </div>
        </div>
    );
}