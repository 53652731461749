import Divider from "components/common/Divider"
import { Footer } from "components/common/Footer"
import { LightBox } from "components/common/LightBox"
import H1 from "components/html/H1"
import Navbar from "components/Navbar"
import { ReactNode } from "react"

type Props = {
    heading: string;
    children: ReactNode;
}

export const ContentLayout = (props: Props) => {

    const { heading, children } = props

    return (<>

        <div className='relative'>
            <Navbar />
        </div>
        <div className="py-1 my-1 mx-auto px-2 sm:px-4 lg:px-8 max-w-7xl space-y-4">
            <div className="space-y-2">
                <LightBox className='p-2 sm:p-4 '>
                    <h2 className="text-xl sm:text-2xl font-bold">{heading}</h2>
                </LightBox>

                <LightBox className='p-2 sm:p-4 '>
                    {children}
                </LightBox>

            </div>
            <Footer />
        </div>

        {/* 
        <div className="py-4 my-4 mx-auto px-2 sm:px-4 lg:px-8 max-w-7xl space-y-4">
            <div className="p-4 sm:p-8 rounded-lg backdrop-opacity-50 bg-white/75 ">
                <H1>{heading}</H1>
                <Divider />
                <div>
                    {children}
                </div>
            </div>
            <Footer />
        </div> */}
    </>)
}