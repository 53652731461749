import { useEffect } from 'react';
// import './App.css';

// import Main from './ex/_Main'
// import ExContainer from './ex/ExDisplayList'
// import ExText from './ex/ExText';
// import ExGraphics from './ex/ExGraphics';
// import ExSprite from './ex/ExSprite';
// import ExDisplayList from './ex/ExDisplayList';
// import ExBitMapText from './ex/ExBitMapText';
// import ExFilters from './ex/ExFilters';
import { Manager } from './lib/Manager';
import { LoaderScene } from './lib/scenes/LoaderScene';

import Main from 'Main';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  useEffect(() => {
    // const main = new Main()
    // const app = main.init()

    // ex 1
    // const exDisplayList = new ExDisplayList()
    // exDisplayList.run(app)


    // ex 2
    // const exContainer = new ExContainer()
    // exContainer.run(app)

    // ex 3
    // const exSprite = new ExSprite()
    // exSprite.run(app)

    // ex 4
    // const exGraphics = new ExGraphics()
    // exGraphics.run(app)

    // ex 5
    // const exText = new ExText()
    // exText.run(app)

    // ex 6
    // const exBitText = new ExBitMapText()
    // exBitText.run(app)

    // ex 7
    // const exFilters = new ExFilters()
    // exFilters.run(app)

    // Manager.initialize(0x26B98C) // 0x066E6F 0x6495ed
    // Manager.initialize(0x066E6F) // 0x066E6F 0x6495ed
    // Manager.initialize(0x6495ed) // 0x066E6F 0x6495ed
    // Manager.initialize(0x93CFEC) // 0x066E6F 0x6495ed
    // Manager.initialize(0x66BDEF) // 0x066E6F 0x6495ed
    Manager.initialize(0xADD8E6) // 0x066E6F 0x6495ed





    const loady: LoaderScene = new LoaderScene(Manager.width, Manager.height)

    Manager.changeScene(loady)
  }, [])


  return (
    <div >
      <div className='' style={{ top: 0, position: 'fixed', overflowX: 'hidden', overflowY: 'hidden' }}>
        <div id='pixi-content'>
          <canvas id='pixi-canvas'></canvas>
        </div>
      </div>

      <Main />
      <ToastContainer />
    </div>
  );
}

export default App;
