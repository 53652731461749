import { useState } from "react"
import SwipeButton from './SwipeButton'
import { RiLoader2Line } from '@remixicon/react'
import axios from 'axios'

export type PaymentResponse = {
    amount: number;
    currency: 'USD' | 'BSV'
    identity: string;
    paymail: string;
    rawTx: string;
    satoshis: number;
    txid: string;
}

type Props = {
    to: string;
    amount: number;
    onPayment: () => void;
    onError: (err: any) => void;
    onReset: () => void;
    disabled: boolean;
}

// const spendLimitError = 'Request exceeds users global connect spend limit.'
// const insufficientFundError = 'Insufficient fund.'

const PayButton = (props: Props) => {

    const [error, setError] = useState<string>('')
    const { to, amount, onPayment, onError, disabled, onReset } = props

    const [loading, setLoading] = useState<boolean>(false)

    const getUnlockedText = () => {
        if (loading) {
            return <div className="flex items-center justify-center">
                <RiLoader2Line size={20} color='white' className='animate-spin mr-2' />
                <p className="mb-0" style={{ marginLeft: '5px' }}>Processing...</p>
            </div>
        } else if (error) {
            return <p className="mb-0">{error}</p>
        } else {
            return <p className="mb-0">Paid</p>
        }
    }
    const getColor = () => {

        const green = `linear-gradient(#31C475, #16B15D)`
        if (disabled && !loading && !error) {
            return `linear-gradient(#AAA, #999)`
        } else if (loading || !error) {
            return green
        } else {
            return `linear-gradient(#B22222, #FF0000)`
        }
    }


    const pay = async () => {
        try {
            setLoading(true)

            return await onPayment()

        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                setError((err as any).response?.data?.message as string)
            } else {
                setError((err as any)?.message as string)
            }
        } finally {
            // setTimeout(() => {
            setLoading(false)
            // }, 5000)
        }

    }

    const reset = () => {
        setError('')
        onReset()
    }


    return <div className="text-center">
        <SwipeButton
            onReset={() => reset()}
            showReset={!!error}
            disabled={disabled}
            text={`Pay ${amount} BSV`}
            text_unlocked={getUnlockedText()}
            color={getColor()}
            onSuccess={() => { pay() }}
            onFailure={() => { console.log('failed') }}
        />
        {/* {error === spendLimitError && <a
            href={`https://app.handcash.io/#/settings/spendLimits`}
            target="_blank"
            rel="noopener"
            className="btn btn-light mt-3"
        >Increase spend limit</a>} */}
    </div>
}

export default PayButton