import { useAppContext } from "context/AppContext";
import { GetSignatures, PurchaseOrdinal, SendBsv, SendBsvResponse, SignMessage, SignatureResponse, SignedMessage, TransferOrdinal, Utxos, usePandaWallet } from "panda-wallet-provider";
import { useEffect, useState } from "react";

type Props = {
    provider: 'panda'
}

type Utxo = Utxos

export function useWallet(props: Props) {

    const { provider } = props

    const { authenticated, currentUser, deauthenticate } = useAppContext()
    const [isInstalled, setIsInstalled] = useState<boolean>(false)

    const pandaWallet = usePandaWallet()

    useEffect(() => {
        if (provider === 'panda' && pandaWallet) {
            setIsInstalled(pandaWallet.isReady)

            if (pandaWallet.isReady) {
                typeof (pandaWallet as any).on === 'function' && (pandaWallet as any).on('signedOut', async () => {
                    if (authenticated) {
                        await deauthenticate()
                    }
                });
            }
        }


    }, [provider, pandaWallet])

    const isConnected = async (): Promise<boolean> => {
        let connected = false
        // console.log('isConnected')
        if (provider === 'panda') {
            // console.log('isConnected panda')
            connected = await pandaWallet.isConnected()
            // console.log('isConnected panda connected', connected)
        }

        return connected
    }

    const connect = async () => {
        if (provider === 'panda') {
            // console.log('connect')
            const r = await pandaWallet.connect()
            // console.log(r)
            await new Promise(resolve => setTimeout(resolve, 2000))
        }
    }

    const disconnect = async (): Promise<boolean> => {
        let disconnected = false

        if (provider === 'panda') {
            disconnected = await pandaWallet.disconnect()
        }

        return disconnected
    }


    const openInstallLink = () => {
        if (provider === 'panda') {
            window.open(
                "https://chromewebstore.google.com/detail/panda-wallet/mlbnicldlpdimbjdcncnklfempedeipj",
                "_blank"
            );
        }
    }

    const getAddresses = async (): Promise<{
        payAddress: string;
        ordAddress: string;
        identityAddress: string;
    } | undefined> => {
        // console.log('preExecution')
        await preExecution()

        if (provider === 'panda') {
            const addresses = await pandaWallet.getAddresses()


            if (
                authenticated &&
                (
                    currentUser.payAddress !== addresses?.bsvAddress ||
                    currentUser.ordAddress !== addresses?.ordAddress ||
                    currentUser.identityAddress !== addresses?.identityAddress
                )
            ) {
                // localStorage.setItem('logout', Date.now().toString())
                await deauthenticate()
                alert('Wallet mismatch')
                throw Error('wallet mismatch')
            }

            return {
                payAddress: addresses?.bsvAddress as string,
                ordAddress: addresses?.ordAddress as string,
                identityAddress: addresses?.identityAddress as string
            }
        }
    }

    const getPubKeys = async (): Promise<{
        payPubKey: string;
        ordPubKey: string;
        identityPubKey: string;
    } | undefined> => {

        await preExecution()

        if (provider === 'panda') {
            const addresses = await pandaWallet.getPubKeys()
            return {
                payPubKey: addresses?.bsvPubKey as string,
                ordPubKey: addresses?.ordPubKey as string,
                identityPubKey: addresses?.identityPubKey as string
            }
        }
    }

    const sendBsv = async (paymentParams: SendBsv[]): Promise<SendBsvResponse> => {

        await preExecution()

        let txid: string, rawtx: string

        if (provider === 'panda') {
            const res = await pandaWallet.sendBsv(paymentParams)
            txid = res?.txid as string
            rawtx = res?.rawtx as string
        }

        return { txid: txid!, rawtx: rawtx! }
    }

    const getPaymentUtxos = async (): Promise<Utxo[]> => {

        await preExecution()

        let utxos: Utxo[] = []

        if (provider === 'panda') {
            utxos = await pandaWallet.getPaymentUtxos() as Utxo[]
        }

        return utxos
    }

    const signMessage = async (params: SignMessage): Promise<SignedMessage> => {

        await preExecution()

        let signedMessage: SignedMessage

        // if(provider === 'panda'){
        signedMessage = await pandaWallet.signMessage(params) as SignedMessage
        // }

        return signedMessage
    }

    const getSignatures = async (params: GetSignatures): Promise<SignatureResponse[]> => {

        await preExecution()

        let sigReponses: SignatureResponse[] = []

        if (provider === 'panda') {
            sigReponses = await pandaWallet.getSignatures(params) as SignatureResponse[]
        }

        return sigReponses
    }

    const transferOrdinal = async (params: TransferOrdinal): Promise<string> => {
        await preExecution()

        let txid: string
        if (provider === 'panda') {
            txid = await pandaWallet.transferOrdinal(params) as string;
        }

        return txid!

    }

    const purchaseOrdinal = async (params: PurchaseOrdinal): Promise<string> => {

        await preExecution()

        let txid: string
        if (provider === 'panda') {
            txid = await pandaWallet.purchaseOrdinal(params) as string;
        }

        return txid!


    }

    // Internal functions

    const preExecution = async () => {
        const connected = await isConnected()
        if (!connected) {
            const res = await connect()
            console.log('res', res)
        }
    }

    return {
        isInstalled,
        openInstallLink,
        isConnected,
        connect,
        disconnect,
        getAddresses,
        getPubKeys,
        sendBsv,
        signMessage,
        getPaymentUtxos,
        getSignatures,
        transferOrdinal,
        purchaseOrdinal
    };
}