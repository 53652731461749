import axios, { AxiosRequestConfig } from 'axios'
import { v4 as uuid } from 'uuid'
import { User } from 'context/AppContext';
import { ACCESS_TOKEN_STORAGE_KEY, CURRENT_USER_STORAGE_KEY } from 'config';

const network = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
});


network.interceptors.request.use((config: AxiosRequestConfig) => {
    try {
        const ttid = uuid()
        const rawToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) as string
        const token = rawToken ? JSON.parse(rawToken) : null

        let currentUser

        const currentUserRaw = localStorage.getItem(CURRENT_USER_STORAGE_KEY) as string

        if (currentUserRaw) {
            currentUser = JSON.parse(currentUserRaw) as User;
        }

        if (token) {
            (config.headers as any)['Authorization'] = `Bearer ${token}`
        }

        (config.headers as any)['X-ttid'] = `${token ? currentUser?.ordAddress + '|' : ''}${ttid}`

    } catch (error) {
        console.error('Error in request interceptor', error)
        throw error
    }
    return config
})

export { network }