import axios, { AxiosResponse } from 'axios'

export interface Unspent1SatTransaction {
    txid: string;
    vout: number;
    outpoint: string;
    satoshis: number;
    accSats: string;
    height: number;
    idx: string;
    owner: string;
    spend: string;
    origin: {
        outpoint: string;
        data: {
            map: {
                app: string;
                name: string;
                type: string;
                subType: string;
                royalties: string;
                subTypeData: {
                    traits: {
                        name: string;
                        value: string;
                    }[];
                    collectionId: string;
                };
            };
            insc: {
                file: {
                    hash: string;
                    size: number;
                    type: string;
                };
            };
            sigma: {
                vin: number;
                valid: boolean;
                address: string;
                algorithm: string;
                signature: string;
            }[];
            types: string[];
        };
        num: string;
    };
    data: {
        map: {
            app: string;
            name: string;
            type: string;
            subType: string;
            royalties: string;
            subTypeData: {
                traits: {
                    name: string;
                    value: string;
                }[];
                collectionId: string;
            };
        };
        insc: {
            file: {
                hash: string;
                size: number;
                type: string;
            };
        };
        sigma: {
            vin: number;
            valid: boolean;
            address: string;
            algorithm: string;
            signature: string;
        }[];
        types: string[];
    };
}

const network = axios.create({
    baseURL: 'https://ordinals.gorillapool.io/api',
});

// Define the function to fetch collection items by collection ID
async function getCollectionItemsByCollectionId(collectionId: string, address?: string): Promise<Unspent1SatTransaction[]> {
    try {
        // Serialize the query object to JSON
        const queryObject = {
            map: {
                subType: "collectionItem",
                subTypeData: {
                    collectionId
                }
            }
        };
        const jsonString = JSON.stringify(queryObject);

        // Encode the JSON string to Base64
        const base64query = window.btoa(jsonString);

        // Make the request to the API
        let data
        if (address) {
            const res: AxiosResponse<Unspent1SatTransaction[]> = await network.get(`/txos/address/${address}/unspent?limit=2700&offset=0&q=${base64query}`);
            data = res.data
        } else {
            const res: AxiosResponse<Unspent1SatTransaction[]> = await network.get(`/locks/search?limit=2700&offset=0&q=${base64query}`);
            data = res.data
        }

        return data;
    } catch (error) {
        console.error('Error fetching collection items:', error);
        throw error;
    }
}

async function getMarketListing(collectionId: string): Promise<Unspent1SatTransaction[]> {
    try {
        // Serialize the query object to JSON
        const queryObject = {
            map: {
                subType: "collectionItem",
                subTypeData: {
                    collectionId
                }
            }
        };
        const jsonString = JSON.stringify(queryObject);

        // Encode the JSON string to Base64
        const base64query = window.btoa(jsonString);

        // Make the request to the API
        const { data }: AxiosResponse<Unspent1SatTransaction[]> = await network.get(`/market?limit=2700&offset=0&q=${base64query}`);

        return data;
    } catch (error) {
        console.error('Error fetching collection items:', error);
        throw error;
    }
}


export default { getCollectionItemsByCollectionId, getMarketListing }