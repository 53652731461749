import { classNames } from "helpers"
import { ReactNode } from "react"

type Props = {
    children: ReactNode | ReactNode[]
    [key: string]: any
}
export const Box = (props: Props) => {
    const { children, ...rest } = props

    return <div className={
        classNames(
            `overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-md bg-base-100/75`,
            rest.className || ''
        )}>
        {children}
    </div>
}