
export const Sanke = () => {
    return (
        <div className="grid grid-cols-12 gap-2 p-3">
            <div className="col-span-12 sm:col-span-6 pt-3">
                <p className="mb-2"><strong>White koi with red patterns and black patches</strong></p>
                <p>{`A Sanke may be one of the most popular koi varieties in Japanese style koi ponds and water gardens. Originally known as Taisho Sanke or Taisho Sanshoku they are commonly called Sanke by most koi enthusiasts in the hobby today. The word Sanke literally means ‘tri color’ in the Japanese language. A Sanke is a koi with three colors; white, red and black. It can be identified as a white koi with a red dorsal pattern and occasional black patches. `}</p>
                <p>{`A good quality Sanke has a pleasing Kohaku pattern of Hi (red) with the addition of sumi (black) patches as accent markings, all on a clean white base color`}</p>
                <p>{`Sanke have more varied patterns than Kohaku due to the highly variable addition of Sumi. The Hi(red) pattern should be that of a good Kohaku, and the sumi should be that of a good Shiro Bekko. Sumi patches  should only be located above the lateral line. The Sanke head should be free of sumi.`}</p>
            </div>
            <div className="col-span-12 sm:col-span-6 flex justify-center pt-2 flex-wrap">
                <div className="text-center">
                    <img src="/full/910.png" width={200} />
                    <p className="m-0">Sanke</p>
                    <p className="text-secondary" style={{ fontSize: '12px' }}>Common scales</p>
                </div>
                <div className="text-center">
                    <img src="/full/909.png" width={200} />
                    <p className="m-0">Sanke</p>
                    <p className="text-secondary" style={{ fontSize: '12px' }}>Doitsu scales</p>
                </div>

            </div>
        </div>
    );
}