import { DropShadowFilter } from 'pixi-filters';
import { Container, Graphics, Point, SimpleRope, Sprite, Text, TextStyle, Texture } from 'pixi.js'
import { degreesToRadians, randomFloatFromInterval } from '../helpers';
import { InfoText } from './InfoText';

import _ from 'lodash'


export class LotusLeaf extends Container {

    m: Graphics = new Graphics()
    g: Graphics = new Graphics()
    rotateDirection = Math.random() > 0.5 ? 'left' : 'right'
    originalAngle: number = (Math.random() > 0.5 ? -1 : 1) * (Math.random() * 180)

    constructor(x: number, y: number) {
        super()



        this.generateTypeA(x, y)

        // this.g.lineStyle(100, 0x6DC557, 1);
        // this.g.arc(x, y, 50, Math.PI, 2.90 * Math.PI);
        // this.g.lineStyle(80, 0x8BCC3B, 1);
        // this.g.arc(x, y, 40, Math.PI, 2.90 * Math.PI);
        // this.g.lineStyle(5, 0x6DC557, 1);
        // this.g.beginFill(0x8BCC3B, 1);
        // this.g.drawCircle(x, y, 50);
        // this.g.endFill();

        // Circle + line sty



        // this.leaf = Sprite.from(Math.random() > 0.5 ? 'leaf3' : "leaf4");

        // this.leaf.angle = this.originalAngle+(Math.random()*10)
        // this.leaf.x = x
        // this.leaf.y = y

        // this.leaf.anchor.set(0.5, 0.5)

        // this.leaf.scale.set(randomFloatFromInterval(0.1, 0.3))
        // this.leaf.tint = _.shuffle([0x3ED33E, 0x67EA54, 0x75D2a5])[0]

        const dropShadowFilter = new DropShadowFilter({
            blur: 1,
            quality: 3,
            alpha: 0.4,
            distance: 40,
            rotation: 90
        })

        this.filters = [dropShadowFilter]

    }


    generateTypeA(x: number, y: number) {

        const container = new Container()

        this.m.beginFill(0x8BCC3B, 1);
        this.m.drawCircle(x, y, 50);
        this.m.endFill();

        this.mask = this.m


        this.g.beginFill(0x6DC557);
        this.g.drawRect(x, y - 50, 50, 100);
        this.g.endFill();

        this.g.beginFill(0x8BCC3B);
        this.g.drawRect(x - 50, y - 50, 50, 100);
        this.g.endFill();

        container.addChild(this.g)

        this.addChild(container)

    }


    generateTypeB(x: number, y: number) {
        this.m.moveTo(x,y)
        this.m.beginFill(0x8BCC3B, 1);
        this.m.drawCircle(0, 0, 50);
        this.m.endFill();

        this.mask = this.m

        this.g.moveTo(x, y)
        this.g.angle += 1

        this.g.beginFill(0x6DC557);
        this.g.drawRect(0, 0 - 50, 50, 100);
        this.g.endFill();

        this.g.beginFill(0x8BCC3B);
        this.g.drawRect(0 - 50, 0 - 50, 50, 100);
        this.g.endFill();

        this.addChild(this.g)

    }



    public update(): void {



        // if (this.leaf.angle > this.originalAngle + 10) {
        //     this.rotateDirection = 'right'
        // } else if (this.leaf.angle < this.originalAngle) {
        //     this.rotateDirection = 'left'
        // }

        // if (this.rotateDirection === 'right') {
        //     this.leaf.x += 0.2
        //     this.leaf.y -= 0.1
        //     this.leaf.angle -= 1 / 300 * (this.leaf.angle - this.originalAngle) + 0.10
        // } else {
        //     this.leaf.x -= 0.2
        //     this.leaf.y += 0.1
        //     this.leaf.angle += 1 / 300 * (this.leaf.angle - this.originalAngle) + 0.10
        // }

    }

    public collide(other: LotusLeaf) {
        const bounds1 = this.getBounds();
        const bounds2 = other.getBounds();

        return bounds1.x < bounds2.x + bounds2.width
            && bounds1.x + bounds1.width > bounds2.x
            && bounds1.y < bounds2.y + bounds2.height
            && bounds1.y + bounds1.height > bounds2.y;
    }

}






// export class LotusLeaf extends Container {

//     leaf: Sprite;

//     rotateDirection = Math.random() > 0.5 ? 'left' : 'right'
//     points: Point[] = []
//     originalAngle: number = (Math.random() > 0.5 ? -1 : 1) * (Math.random() * 180)

//     constructor(x: number, y: number) {
//         super()

//         console.log('rotateDirection', this.rotateDirection)

//         this.leaf = Sprite.from(Math.random() > 0.5 ? 'leaf3' : "leaf4");

//         this.leaf.angle = this.originalAngle+(Math.random()*10)
//         this.leaf.x = x
//         this.leaf.y = y

//         this.leaf.anchor.set(0.5, 0.5)

//         this.leaf.scale.set(randomFloatFromInterval(0.1, 0.3))
//         this.leaf.tint = _.shuffle([0x3ED33E, 0x67EA54, 0x75D2a5])[0]

//         this.addChild(this.leaf)

//         const dropShadowFilter = new DropShadowFilter({
//             blur: 1,
//             quality: 3,
//             alpha: 0.5,
//             distance: 50,
//             rotation: 90
//         })

//         this.filters = [dropShadowFilter]

//     }

//     public update(): void {



//         if (this.leaf.angle > this.originalAngle + 10) {
//             this.rotateDirection = 'right'
//         } else if (this.leaf.angle < this.originalAngle) {
//             this.rotateDirection = 'left'
//         }

//         if (this.rotateDirection === 'right') {
//             this.leaf.x += 0.2
//             this.leaf.y -= 0.1
//             this.leaf.angle -= 1 / 300 * (this.leaf.angle - this.originalAngle) + 0.10
//         } else {
//             this.leaf.x -= 0.2
//             this.leaf.y += 0.1
//             this.leaf.angle += 1 / 300 * (this.leaf.angle - this.originalAngle) + 0.10
//         }

//     }

//     public collide(other: LotusLeaf) {
//         const bounds1 = this.getBounds();
//         const bounds2 = other.getBounds();

//         return bounds1.x < bounds2.x + bounds2.width
//             && bounds1.x + bounds1.width > bounds2.x
//             && bounds1.y < bounds2.y + bounds2.height
//             && bounds1.y + bounds1.height > bounds2.y;
//     }

// }

