// Localstorage keys
export const REF_CODE_STORAGE_KEY = "refCode"
export const ACCESS_TOKEN_STORAGE_KEY = "accessToken"
export const AUTHENTICATED_STORAGE_KEY = "authenticated"
export const CURRENT_USER_STORAGE_KEY = "currentUser"
export const MUSIC_VOLUME_STORAGE_KEY = "musicVolume"
export const SOUND_EFFECTS_VOLUME_STORAGE_KEY = "soundEffectsVolume"

// Events
export const MUSIC_VOLUME_CHANGE_EVENT = "musicVolumeChange"
export const SOUND_EFFECTS_VOLUME_CHANGE_EVENT = "soundEffectsVolumeChange"