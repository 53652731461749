import { squeezeAddress } from "helpers";


type Props = {
    address: string;
}

const TruncatedAddress = ({ address }: Props) => {
    return <span className="text-gray-400">{squeezeAddress(address)}</span>
}

export { TruncatedAddress }