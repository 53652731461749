import Divider from "components/common/Divider";
import { Footer } from "components/common/Footer";
import H1 from "components/html/H1";
import { useAppContext } from "context/AppContext";
import { useEffect, useRef, useState } from "react";
// import data from '../../data/data.json'
import { getCdnEndpointByEnv, sleep, toast } from 'helpers'
import _ from 'lodash'
import { toast as t, ToastPosition } from 'react-toastify';
import Loading from "components/common/Loading";
import PayButton from "components/PayButton";
import Navbar from "components/Navbar";
import { REF_CODE_STORAGE_KEY } from "config";
import InitialSaleNftApi, { InitialSaleNFT } from "network/internal/InitialSaleNft.api";
import { useTxBuilder } from "hooks/useTxBuilder";
import { useLocalStorage } from "usehooks-ts";
import { useSearchParams } from "react-router-dom";

// const allKois = data.kois

export type Koi = {
    id: number;
    num: number;
    gender: string;
    klass: string;
}

const TOTAL_SUPPLY = 2700

export const BuyPage = () => {

    const { authenticated } = useAppContext()
    const { createFundingTx } = useTxBuilder()
    const [searchParams] = useSearchParams()

    const [refCode] = useLocalStorage<string>(REF_CODE_STORAGE_KEY, '')

    const toastId = useRef<any>(null)

    const [previewOpened, setPreviewOpened] = useState<boolean>(false)

    const [selectedNft, setSelectedNft] = useState<any>()
    const [lastTxid, setLastTxid] = useState<string>()
    const [availableNftsCount, setAvailableNftsCount] = useState<number>(TOTAL_SUPPLY)
    const [refreshingAvailableNfts, setRefreshingAvailableNfts] = useState<boolean>(false)
    const [errorRefreshing, setErrorRefreshing] = useState<boolean>(false)
    const [buying, setBuying] = useState<boolean>(false)

    useEffect(() => {
        refreshAvailableNfts()
    }, [])

    // useEffect(() => {
    //     refreshCountAvailableKois()
    // }, [outerWidth])

    const refreshAvailableNfts = async () => {
        try {
            setErrorRefreshing(false)
            setRefreshingAvailableNfts(true)
            const { count } = await InitialSaleNftApi.count(process.env.REACT_APP_KOI_COLLECTION_ID as string)
            setAvailableNftsCount(count)
        } catch (err) {
            toast('error', 'Something went wrong. Please contact support.')
            setErrorRefreshing(true)
        } finally {
            setRefreshingAvailableNfts(false)
        }
    }

    const buyAvailableNft = async () => {
        try {

            setBuying(true)

            const { tx, utxos } = await createFundingTx()

            const { nft, txid } = await InitialSaleNftApi.buy({
                rawtx: tx.to_hex(),
                utxos,
                collectionId: process.env.REACT_APP_KOI_COLLECTION_ID as string,
                refCode,
                koiId: searchParams.get('koi_id')? parseInt(searchParams.get('koi_id') as string): undefined
            })

            refreshAvailableNfts()

            toastId.current = t('Congratulations!', {
                type: t.TYPE.INFO,
                position: 'top-center' as ToastPosition,
                autoClose: 1000 * 20,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })

            // const _koi = allKois.find((koi: any) => koi.id === data.nft.id)
            setSelectedNft(nft)
            setLastTxid(txid)
            await sleep(1000)
            setPreviewOpened(true)
            t.update(toastId.current, { render: getCongratulationText(nft), type: t.TYPE.SUCCESS, autoClose: 5000 })

            await sleep(5000)

            t.update(toastId.current, { render: 'The nft will be transferred to you in a moment.', type: t.TYPE.SUCCESS, autoClose: 5000 })

            await sleep(2000)

        } catch (err: any) {

            if (err?.message === 'Insufficient fund.') {
                throw err
            } else if ((err as any).response?.status === 400) {
                if ((err as any).response?.data.message.indexOf('Sorry all koi fishes have been sold out.') > -1) {
                    toast('error', (err as any).response?.data.message)
                    throw { response: { data: { message: 'Error' } } }
                } else {
                    throw err
                }
            } else {
                // console.log(err)
                // t.update(toastId.current, { type: t.TYPE.ERROR, render: 'Something went wrong. Please contact support.' })
                throw Error('Something went wrong.')
            }
        } finally {
            setBuying(false)

        }
    }


    const getCongratulationText = (nft: InitialSaleNFT) => {
        let str

        let classification = nft.metadata.subTypeData.traits.find(trait => trait.name === 'classification')!.value
        if (['Asagi', 'Utsurimono'].includes(classification)) {
            str = 'an'
        } else {
            str = 'a'
        }
        return `You've acquired ${str} ${classification}.`
    }


    return (
        <>
            <div className='relative'>
                <Navbar />
            </div>
            <div className="py-4 my-4 mx-auto px-2 sm:px-4 lg:px-8 max-w-7xl space-y-4">

                <div className="grid grid-cols-2">
                    <div className="col-span-2 sm:col-span-1">
                        <div style={{ minHeight: '550px' }} className="w-full pt-5 pb-3 flex items-end justify-center">
                            <div className={'box' + (previewOpened ? ' opened' : '')}>
                                <div>

                                    {selectedNft && <img className="img" src={`${getCdnEndpointByEnv()}/inscriptions/koi/small-vertical/${selectedNft.outpoint}.png`} />}
                                    {/* {<img className="img" src={`/small/1.png`} />} */}
                                </div>
                                <div className={'box-body'}>
                                    <div className="box-lid">
                                        <div className="box-bowtie"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                        <div>
                            <div className="p-4 sm:p-8 rounded-lg backdrop-opacity-50 bg-base-100/75 ">
                                <H1>{availableNftsCount > 0 ? 'Buy your own koi NFT now' : 'Sold out!'}</H1>
                                <Divider />
                                <div>
                                    <div className='space-y-4'>
                                        {availableNftsCount > 0 ? <>
                                            <p className="text-lg font-bold">1st drop {parseFloat(process.env.REACT_APP_PRESALE_PRICE as string)} BSV (save 65%)</p>
                                            <p>{refreshingAvailableNfts ? 'loading...' : (errorRefreshing ? 'Unknown' : availableNftsCount)} of {TOTAL_SUPPLY} koi fishes left</p>
                                            <p>When making a purchase, a random NFT from the collection will be selected to ensure equitable distribution and to add an element of excitement!</p>
                                            <p>Where can I buy my BSV? <a className="text-red-400 font-bold underline" target="_blank" rel='noopener' href="https://buybsv.com/">BuyBSV.com</a></p>
                                            <p>Which wallet should I use? <a className="text-red-400 font-bold underline" target="_blank" rel='noopener' href="https://chromewebstore.google.com/detail/panda-wallet/mlbnicldlpdimbjdcncnklfempedeipj">Panda Wallet</a></p>


                                            <div style={{ height: '70px' }}>
                                                {refreshingAvailableNfts
                                                    ? <Loading />
                                                    : (errorRefreshing ? <button className='btn' onClick={() => {
                                                        refreshAvailableNfts()
                                                    }}>Refresh</button>

                                                        : <>
                                                            {selectedNft && !buying
                                                                ? <div className="d-flex">
                                                                    <button className='btn' onClick={() => {
                                                                        setSelectedNft(undefined)
                                                                        setLastTxid('')
                                                                        setPreviewOpened(false)
                                                                    }}>Buy another one</button>
                                                                    <a href={`https://whatsonchain.com/tx/${lastTxid}`} target="_blank" rel="noopener" className="mx-2 btn">View on WhatsOnChain</a>
                                                                </div>
                                                                : <PayButton
                                                                    to={process.env.REACT_APP_PRESALE_RECEIVE_ADDRESS as string}
                                                                    amount={parseFloat(process.env.REACT_APP_PRESALE_PRICE as string)}
                                                                    onPayment={async () => {
                                                                        // const { txid } = paymentResponse

                                                                        await buyAvailableNft()
                                                                    }}
                                                                    onError={(err) => {
                                                                        toast('error', err.message)
                                                                    }}
                                                                    onReset={()=>{
                                                                        refreshAvailableNfts()
                                                                    }}
                                                                    disabled={!authenticated || buying}
                                                                />}
                                                        </>)}

                                            </div>


                                        </>
                                            : <p>
                                                All NFTs have been sold out. {/*Stay tuned for our next drop.*/}
                                            </p>
                                        }
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>



            </div>
        </>

    );
}