
export const Koromo = () => {
    return (
        <div className="grid grid-cols-12 gap-2 p-3">
            <div className="col-span-12 sm:col-span-4 pt-3">
                <p className="mb-2"><strong>White koi with a red dorsal pattern that are outlined in blue or black</strong></p>
                <p>{`Koromo koi fish are sure to stand out in a Japanese koi pond or water garden because of its unusual colors. The Koromo are white koi fish with a Kohaku pattern of red and blue or black edging on just the red scales. This feature earned Koromo the title of ‘robed-koi’. A well balanced Kohaku pattern is ideal on a high quality koi.`}</p>
                <p>{`Koromo literally means ‘robed’ in Japanese. Koromo are said to have been produced by crossing Kohaku with Asagi. Kohaku, Taisho Sanshoku and Showa Sanshoku, which have indigo tinge over-laying the red patterns, are called Ai-goromo (blue garment), Koromo Sanshoku, and Koromo Showa respectively.`}</p>
                <p>{`Koromo usually have blue crescent markings on the edges of scales within the red pattern. Koi with distinct, blue crescents arranged in an orderly manner are highly valued. High quality Koromo such as this are tastefully charming -- the kind favored by Koi experts.`}</p>
            </div>

            <div className="col-span-12 sm:col-span-8">
                <div className="flex justify-center pt-2 flex-wrap">
                    <div className="text-center">
                        <img src="/full/2414.png" width={175} />
                        <p>Ai Koromo</p>
                        <p className="text-secondary" style={{ fontSize: '12px' }}>Common scales</p>
                    </div>
                    <div className="text-center">
                        <img src="/full/2402.png" width={175} />
                        <p>Budo Koromo</p>
                        <p className="text-secondary" style={{ fontSize: '12px' }}>Common scales</p>
                    </div>
                    <div className="text-center">
                        <img src="/full/2403.png" width={175} />
                        <p>Sumi Koromo</p>
                        <p className="text-secondary" style={{ fontSize: '12px' }}>Common scales</p>
                    </div>
                </div>

                <div className="flex justify-center pt-2 flex-wrap">
                    <div className="text-center">
                        <img src="/full/2401.png" width={175} />
                        <p>Ai Koromo</p>
                        <p className="text-secondary" style={{ fontSize: '12px' }}>Doitsu scales</p>
                    </div>
                    <div className="text-center">
                        <img src="/full/2406.png" width={175} />
                        <p>Budo Koromo</p>
                        <p className="text-secondary" style={{ fontSize: '12px' }}>Doitsu scales</p>
                    </div>
                    <div className="text-center">
                        <img src="/full/2422.png" width={175} />
                        <p>Sumi Koromo</p>
                        <p className="text-secondary" style={{ fontSize: '12px' }}>Doitsu scales</p>
                    </div>
                </div>


            </div>
        </div>
    );
}