import React, { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Link, useLocation } from 'react-router-dom'
import { classNames } from 'helpers'
import { useNftDropsContext } from 'context/NftDropsContext'
import { useAppContext } from 'context/AppContext'
import { Avatar } from './common/Avatar'
import { TruncatedAddress } from './common/TruncatedAddress'
import { RiSearchLine, RiMenuLine, RiCloseLine } from '@remixicon/react'
const menues = [{
    label: 'Home',
    to: '/'
}, {
    label: 'NFT',
    to: '/nft'
}, {
    label: 'Buy',
    to: '/buy'
}, {
    label: 'Marketplace',
    to: '/marketplace'
}, {
    label: 'Koi Classes',
    to: '/koi-classes/kohaku'
}, {
    label: 'Glossary',
    to: '/glossary'
}, {
    label: 'About',
    to: '/about'
}]


export default function Navbar() {

    const { setSearchQuery } = useNftDropsContext()
    const { authenticate, currentUser, authenticated, loginDisabled, deauthenticate } = useAppContext()
    const location = useLocation()

    const renderDesktopMainMenu = (menu: { label: string; to: string }) => {
        return <Link key={menu.label} to={menu.to} className={
            classNames(
                location.pathname === menu.to ? `border-red-500 border-b-2` : 'border-transparent',
                `text-gray-900 inline-flex items-center px-1 pt-1 text-sm font-medium
                `)}>
            {menu.label}
        </Link>
    }

    const renderMobileMainMenu = (menu: { label: string; to: string }) => {
        return <Disclosure.Button
            key={menu.label}
            as={Link}
            to={menu.to}
            className={classNames(
                location.pathname === menu.to ? `bg-red-50 border-red-500 text-red-700` : `border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800`,
                'pl-3 pr-4 py-2 border-l-4 text-base font-medium block'
            )}
        >
            {menu.label}
        </Disclosure.Button >


    }


    const renderLogoutMenu = (mobile: boolean) => {
        if (mobile) {
            return <Disclosure.Button
                onClick={() => deauthenticate()}
                as="button"
                className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
            >
                Logout
            </Disclosure.Button>
        } else {
            return <Menu.Item
                as='button'
                onClick={() => deauthenticate()}
                className='block px-4 py-2 text-sm text-gray-700'

            >
                Logout
            </Menu.Item>
        }
    }



    return (
        <Disclosure as="nav" className="shadow-xl relative">
            {({ open }) => (
                <>
                    <div className='absolute backdrop-blur-sm bg-base-100/70 w-full h-full'></div>
                    <div className="relative max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                        <div className="flex justify-between h-16">
                            <div className="flex px-2 lg:px-0">
                                <div className="flex-shrink-0 flex items-center">
                                    <Link to='/'><img style={{ marginRight: '10px' }} src="/logo.png" width="35" height="35" className='rounded-full' /></Link>
                                </div>
                                <div className="hidden sm:ml-2 sm:flex sm:space-x-4 xl:ml-4 xl:flex xl:space-x-8">
                                    {menues.map(renderDesktopMainMenu)}
                                </div>
                            </div>

                            <div className="flex-1 flex items-center justify-center px-2 lg:ml-3 lg:justify-end">


                                {/* {authenticated && <div className="hidden sm:flex pr-2">
                                    <Link to='/gaming/test' className={
                                        classNames(
                                            'cursor-pointer border-transparent',
                                            `text-gray-900 inline-flex items-center px-1 pt-1 text-sm font-medium`
                                        )}>
                                        Switch to gaming
                                    </Link>
                                </div>} */}


                                <div className="max-w-lg lg:max-w-xs">
                                    <label htmlFor="search" className="sr-only">
                                        Search koi class
                                    </label>
                                    <div className="relative">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <RiSearchLine size={16} color='gray' />
                                        </div>
                                        <input
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setSearchQuery(e.target.value)
                                            }}
                                            id="search"
                                            name="search"
                                            className="block w-52 pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-base-100 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-sky-500 focus:border-indigo-500 sm:text-sm"
                                            placeholder="Search koi class"
                                            type="search"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center lg:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <RiCloseLine size={16} color='gray' />
                                    ) : (
                                        <RiMenuLine size={16} color='gray' />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="hidden lg:ml-4 lg:flex lg:items-center">
                                {/* <button
                                    type="button"
                                    className="flex-shrink-0 bg-white p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    <span className="sr-only">View notifications</span>
                                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                                </button> */}


                                {authenticated
                                    ? <>
                                        {/* Profile dropdown */}
                                        <Menu as="div" className="ml-4 relative flex-shrink-0">
                                            <div>
                                                <Menu.Button className="pr-3 space-x-2 bg-base-100 rounded-md flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200 items-center">
                                                    {/* <span className="sr-only">Open user menu</span> */}
                                                    {/* <img
                                                        className="h-6 w-6 rounded-full"
                                                        src={currentUser.avatar}
                                                        alt=""
                                                    /> */}
                                                    <Avatar value={'asd asd'} size="35" />
                                                    <div className="">
                                                        <div className="text-sm font-medium text-gray-800">{
                                                            currentUser.alias
                                                                ? currentUser.alias
                                                                : <TruncatedAddress address={currentUser.ordAddress} />}</div>
                                                        {/* <div className="text-sm font-medium text-gray-500">tom@example.com</div> */}
                                                    </div>
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-base-100 ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    {/* <Menu.Item
                                                        as={Link}
                                                        to={`/${owner.address}/pond`}
                                                        className={'block px-4 py-2 text-sm text-gray-700'}
                                                    // className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                    >
                                                        My Pond
                                                    </Menu.Item> */}
                                                    <Menu.Item
                                                        as={Link}
                                                        to={`/${currentUser.ordAddress}/nfts`}
                                                        className={'block px-4 py-2 text-sm text-gray-700'}
                                                    >
                                                        My NFTs
                                                    </Menu.Item>
                                                    <Menu.Item
                                                        as={Link}
                                                        to={`/settings`}
                                                        className={'block px-4 py-2 text-sm text-gray-700'}
                                                    >
                                                        Settings
                                                    </Menu.Item>
                                                    {renderLogoutMenu(false)}

                                                </Menu.Items>
                                            </Transition>
                                        </Menu>


                                    </>
                                    :
                                    <button
                                        onClick={async () => {
                                            authenticate()
                                            // isOpen ? hide() : show()
                                        }}
                                        className={classNames(
                                            'bg-sky-600 hover:bg-sky-500',
                                            'space-x-2 inline-flex w-full justify-center items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-sky-700 hover:ring-sky-600 transition-colors',
                                            loginDisabled ? 'disabled' : ''
                                        )}>
                                        <img src="/logo/panda-wallet.png" width="22" height="22" className='rounded-full' />
                                        <span>Connect Wallet</span>
                                    </button>
                                }



                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="relative lg:hidden">
                        <div className="pt-2 pb-3 space-y-1">
                            {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800" */}
                            {menues.map(renderMobileMainMenu)}
                        </div>


                        {
                            authenticated
                                ? <div className="pt-4 pb-3 border-t border-gray-200">
                                    <div className="flex items-center px-4">
                                        <div className="flex-shrink-0">
                                            <span className="sr-only">Open user menu</span>
                                            <img
                                                className="h-10 w-10 rounded-full"
                                                src={currentUser.avatar}
                                                alt=""
                                            />
                                        </div>
                                        <div className="ml-3">
                                            <div className="text-base font-medium text-gray-800">{currentUser.alias || currentUser.displayName}</div>
                                            {/* <div className="text-sm font-medium text-gray-500">tom@example.com</div> */}
                                        </div>
                                        {/* <button
                                    type="button"
                                    className="ml-auto flex-shrink-0 bg-white p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    <span className="sr-only">View notifications</span>
                                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                                </button> */}
                                    </div>
                                    <div className="mt-3 space-y-1">

                                        <Disclosure.Button
                                            as={Link}
                                            to={`/${currentUser.ordAddress}/nfts`}
                                            className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                                        >
                                            My NFTs
                                        </Disclosure.Button>

                                        <Disclosure.Button
                                            as={Link}
                                            to={`/settings`}
                                            className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                                        >
                                            Settings
                                        </Disclosure.Button>

                                        {renderLogoutMenu(true)}
                                    </div>
                                </div>
                                : <div className="px-3 pb-3 border-t border-gray-200">
                                    <button
                                        onClick={async () => {
                                            authenticate()
                                            // isOpen ? hide() : show()
                                        }}
                                        className={classNames(
                                            'bg-sky-600 hover:bg-sky-500',
                                            'space-x-2 inline-flex w-full justify-center items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-sky-700 hover:ring-sky-600 transition-colors',
                                            loginDisabled ? 'disabled' : ''
                                        )}>
                                        <img src="/logo/panda-wallet.png" width="22" height="22" className='rounded-full' />
                                        <span>Connect Wallet</span>
                                    </button>
                                </div>
                        }
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}