import { Container, Sprite, Text, TextStyle, Texture } from "pixi.js";

export class InfoText extends Container {

    text: Text;
    bg: Sprite;

    constructor(initialText: string) {
        super()
        const styly: TextStyle = new TextStyle({
            fontSize: 42,
            fill: "black",
            fontFamily: "Impact",
        })

        this.text = new Text(initialText, styly) // Text supports unicode!

        this.bg = new Sprite(Texture.WHITE)
        this.bg.width = this.text.width
        this.bg.height = this.text.height

        this.addChild(this.bg)
        this.addChild(this.text)
    }

    update(text: string) {
        this.text.text = text
        this.bg.width = this.text.width
        this.bg.height = this.text.height
    }

}