import { RiLoader2Line } from "@remixicon/react"

type Props = {
    color?: 'black' | 'white'
}
const Loading = (props: Props) => {

    const color = props.color || 'black'

    return <div className="flex justify-center p-5" style={{ color, width: '100%' }}>
        <RiLoader2Line size={30} className="mr-2.5 animate-spin" />
        <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Loading...</p>
    </div>
}

export default Loading