import Loading from "components/common/Loading";
import { ContentLayout } from "components/Layout.tsx/ContentLayout";
import { REF_CODE_STORAGE_KEY } from "config";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

export const RefCodeRedirectPage = () => {

    const { refCode } = useParams()
    const [_refCode, setRefCode] = useLocalStorage<string>(REF_CODE_STORAGE_KEY, '')

    const navigate = useNavigate()

    useEffect(() => {
        if (refCode) {
            setRefCode(refCode)
            setTimeout(() => {
                navigate('/buy')
            }, 1000)
        }
    }, [refCode])

    return (
        <ContentLayout heading="Loading">
            <div className='space-y-4'>
                <div className="flex justify-center">
                    <Loading />
                </div>
            </div>
        </ContentLayout>
    );
}