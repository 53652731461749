import init, { Script, SigHash, Transaction, TxIn } from 'bsv-wasm-web';
import { hexStringToUint8Array } from 'helpers';
import { useWallet } from './useWallet';
import WhatsOnChainApi, { UTXO } from 'network/external/WhatsOnChain.api';
import { useEffect } from 'react';

export function useTxBuilder() {

    const wallet = useWallet({ provider: 'panda' })

    useEffect(() => {
        init()
    }, [])

    const createFundingTx = async (): Promise<{ utxos: UTXO[], tx: Transaction }> => {
        const addresses = await wallet.getAddresses()

        const { utxos } = await WhatsOnChainApi.fetchUtxos(addresses?.payAddress as string)

        const balance = utxos!.reduce((acc: number, curr: UTXO) => acc + curr.satoshis, 0)

        const priceInSatoshis = Math.round(parseFloat(process.env.REACT_APP_PRESALE_PRICE as string) * 100000000)

        // console.log('balance', balance)
        // console.log('priceInSatoshis', priceInSatoshis)
        if (balance < priceInSatoshis) {
            throw Error('Insufficient fund.')
        } else {

            let totalSatoshis = 0
            let selectedUtxos = []

            for (let i = 0; i < utxos!.length; i++) {
                totalSatoshis += utxos![i].satoshis
                selectedUtxos.push(utxos![i])
                if (totalSatoshis >= priceInSatoshis) {
                    break
                }
            }

            const tx = new Transaction(1, 0)

            // const tx = Transaction.from_hex(`01000000010c087eca233f2c116d305ab56860420f8af7cd55af243999e3da878689762034040000006b483045022100bbb3daa2f30fc3a7952305ee7d73c3786651980ac96c2804b78a54a6e12beed5022069d1aaebbe07754856935774b0294430476f1092cccfe7ff6ba8167b02522272422102edc88167ab69d390ba86f7d87dc484c1dcf191311693dd43a983d378f1a58d75ffffffff0000000000`)

            const sigRequests = []

            for (let i = 0; i < selectedUtxos.length; i++) {
                let selectedUtxo = selectedUtxos[i]
                const {
                    satoshis,
                    // script,
                    txid,
                    vout
                } = selectedUtxo

                const txin = new TxIn(hexStringToUint8Array(txid), vout, Script.from_asm_string(''))

                txin.set_satoshis(BigInt(satoshis))

                tx.add_input(txin)

                sigRequests.push({
                    prevTxid: txid,
                    outputIndex: vout,
                    inputIndex: i,
                    satoshis,
                    address: addresses?.payAddress as string,
                    sigHashType: SigHash.NONE | SigHash.FORKID
                })
            }

            const sigResponses = await wallet.getSignatures({
                rawtx: tx.to_hex(),
                sigRequests
            })

            // console.log(sigResponses)

            for (let sigResponse of sigResponses!) {
                const { inputIndex, pubKey, sig, sigHashType } = sigResponse

                const input = tx.get_input(inputIndex)

                const script = Script.from_asm_string(`${sig} ${pubKey}`)
                input?.set_unlocking_script(script)

                tx.set_input(inputIndex, input!)
            }

            return { tx, utxos: selectedUtxos }
        }
    }



    return {
        createFundingTx
    };
}