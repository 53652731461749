// import Jdenticon from 'react-jdenticon';
import { configure, update } from 'jdenticon';
import { useEffect, useRef } from 'react';

type Props = {
    value: string;
    size?: string;
}

export const Avatar = (props: Props) => {
    const { value, size } = props

    const icon = useRef(null);

    useEffect(() => {
        configure({
            lightness: {
                color: [0.30, 0.70],
                grayscale: [0.25, 0.76]
            },
            saturation: {
                color: 1.00,
                grayscale: 0.51
            },
            backColor: "#000"
        })
    }, [])

    useEffect(() => {
        update(icon.current as any, value);
    }, [value]);

    return (
        <div className='rounded-md overflow-hidden'>
            <svg data-jdenticon-value={value} height={size} ref={icon} width={size} />
        </div>
    );
}