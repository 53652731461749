import { AxiosResponse } from 'axios';
import { network } from './index';

interface AuthenticatePayload {
    payAddress: string;
    ordAddress: string;
    identityAddress: string;
    payPubKey: string;
    ordPubKey: string;
    identityPubKey: string;
    m: string;
    s: string;
}

interface CodeResponse {
    code: string;
}


export async function authenticate(payload: AuthenticatePayload): Promise<any> {
    try {
        const response: AxiosResponse<any> = await network.post(`/users/authenticate`, payload);
        // console.log('data', response.data);
        return response.data;
    } catch (error) {
        console.error('Error authenticating:', error);
        throw error;
    }
}
export async function getReferralCode(): Promise<CodeResponse> {
    try {
        const response: AxiosResponse<CodeResponse> = await network.get(`/users/referral-code`);
        // console.log('data', response.data);
        return response.data;
    } catch (error) {
        console.error('Error getting referral code:', error);
        throw error;
    }
}

export async function generateReferralCode(): Promise<CodeResponse> {
    try {
        const response: AxiosResponse<CodeResponse> = await network.post(`/users/generate-referral-code`, {});
        // console.log('data', response.data);
        return response.data;
    } catch (error) {
        console.error('Error generating referral code:', error);
        throw error;
    }
}

export default { authenticate, getReferralCode, generateReferralCode }