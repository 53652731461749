import { createContext, useContext, useEffect, useState } from "react";
import { toast } from 'helpers'
import UserService from '../network/internal/User.api'
// import ct from 'countries-and-timezones'
import { useNavigate } from "react-router-dom";
import {
    REF_CODE_STORAGE_KEY,
    ACCESS_TOKEN_STORAGE_KEY,
    CURRENT_USER_STORAGE_KEY,
    AUTHENTICATED_STORAGE_KEY
} from 'config'
import { SignMessage } from "panda-wallet-provider";
import axios from "axios";
import moment from "moment";
import { jwtDecode } from "jwt-decode";
import { useWallet } from "hooks/useWallet";
import { useLocalStorage } from 'usehooks-ts'

export type User = {
    _id: string;
    displayName: string
    alias: string;
    avatar: string;
    payAddress: string;
    ordAddress: string;
    identityAddress: string;
    createdAt: string;
}


export type WalletAccount = {
    id: string;
    name: string;
    purseAddress: string;
}

type AppContextProps = {
    currentUser: User;
    loginDisabled: boolean;
    authenticated: boolean;
    authenticate: () => void;
    deauthenticate: () => void;
}

export const initialUser = {
    _id: '',
    displayName: '',
    alias: '',
    avatar: '',
    payAddress: '',
    ordAddress: '',
    identityAddress: '',
    createdAt: ''
}

const AppContext = createContext({
    currentUser: initialUser,
    authenticated: false
} as AppContextProps);


export function AppWrapper({ children }: { children: any }) {

    const navigate = useNavigate();

    const wallet = useWallet({ provider: 'panda' })

    const [loginDisabled, setLoginDisabled] = useState<boolean>(false)

    const [refCode, setRefCode] = useLocalStorage<string>(REF_CODE_STORAGE_KEY, '')
    const [accessToken, setAccessToken] = useLocalStorage<string>(ACCESS_TOKEN_STORAGE_KEY, '')
    const [authenticated, setAuthenticated] = useLocalStorage<boolean>(AUTHENTICATED_STORAGE_KEY, false)
    const [currentUser, setCurrentUser] = useLocalStorage<User>(CURRENT_USER_STORAGE_KEY, initialUser, {
        serializer: JSON.stringify,
        deserializer: JSON.parse,
    })

    useEffect(() => {
        setupDefaultRefCode()

        // Listen for storage events
        window.addEventListener('storage', handleStorageEvent)

        return () => {
            // Cleanup event listener
            window.removeEventListener('storage', handleStorageEvent)
        }
    }, [])



    const authenticate = async () => {

        try {

            if (!wallet.isInstalled) {
                wallet.openInstallLink()
            } else {
                const res = await wallet.connect()

                // console.log('res', res)

                setTimeout(async () => {
                    try {

                        setLoginDisabled(true)

                        const addresses = await wallet.getAddresses();
                        const pubKeys = await wallet.getPubKeys();

                        // console.log('addresses', addresses)
                        // console.log('pubKeys', pubKeys)

                        const message = {
                            message: 'Login time - ' + moment(Date.now()).utc().format('MMMM DD, YYYY HH:mm:ss [UTC]'),
                            encoding: 'utf8'
                        } as SignMessage
                        const signedMessageResponse = await wallet.signMessage(message)!

                        const payload = {
                            payAddress: addresses?.payAddress as string,
                            ordAddress: addresses?.ordAddress as string,
                            identityAddress: addresses?.identityAddress as string,
                            payPubKey: pubKeys?.payPubKey as string,
                            ordPubKey: pubKeys?.ordPubKey as string,
                            identityPubKey: pubKeys?.identityPubKey as string,
                            m: signedMessageResponse?.message as string,
                            s: signedMessageResponse?.sig as string
                        }

                        const res = await UserService.authenticate(payload)

                        setAccessToken(res.token)
                        setCurrentUser(jwtDecode(res.token) as User)
                        setAuthenticated(true)

                    } catch (err) {
                        if (axios.isAxiosError(err)) {
                            toast('error', (err.response?.data as any).message as string)
                        }
                    } finally {
                        setLoginDisabled(false)
                    }


                }, 2000)

            }


        } catch (err) {

            toast('error', 'Something went wrong. Please contact support.')
            console.log(err)


        }

    }

    const setupDefaultRefCode = async () => {
        if (!refCode || (typeof refCode === 'string' && refCode.length !== 36)) {
            setRefCode(process.env.REACT_APP_DEFAULT_REF_CODE as string)
        }
    }

    const deauthenticate = async () => {
        setCurrentUser(initialUser)
        setAuthenticated(false)
        setAccessToken('')
        localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, '')
        localStorage.setItem(CURRENT_USER_STORAGE_KEY, '')

        if(await wallet.isConnected()){
            await wallet.disconnect()
        }

        // Logout action for other tabs
        localStorage.setItem('logout', Date.now().toString())

        navigate('/', { replace: true })
    }

    const handleStorageEvent = (event: StorageEvent) => {
        // if (event.key === 'login') {
        //     setLoginDisabled(true)
        //     setCurrentUser(jwtDecode(accessToken) as User)
        //     setAuthenticated(true)
        //     setLoginDisabled(false)
        //     // navigate('/', { replace: true })
        // }
        if (event.key === 'logout') {
            // Logout action triggered in another tab
            // setCurrentUser(initialUser)
            // setAuthenticated(false)
            // setAccessToken('')
            navigate('/', { replace: true })
        }
    }

    let sharedState: AppContextProps = {
        currentUser,
        loginDisabled,
        authenticated,
        authenticate,
        deauthenticate
    }

    return (
        <AppContext.Provider value={sharedState}>
            {children}
        </AppContext.Provider>
    );
}

export function useAppContext() {
    return useContext(AppContext);
}