import { ContentLayout } from "components/Layout.tsx/ContentLayout";
import { useAppContext } from "context/AppContext";
import { useEffect, useState } from "react";
import UserService from '../network/internal/User.api'
import { classNames, toast } from "helpers";
import axios from "axios";
import copy from 'copy-to-clipboard';
import UserApi from "../network/internal/User.api";
import { RiFileCopy2Line } from "@remixicon/react";
import { CopyButton } from "components/common/CopyButton";
// import { useEffect, useState } from "react";
// import {
//     MUSIC_VOLUME_CHANGE_EVENT,
//     MUSIC_VOLUME_STORAGE_KEY,
//     SOUND_EFFECTS_VOLUME_CHANGE_EVENT,
//     SOUND_EFFECTS_VOLUME_STORAGE_KEY
// } from 'config'

const CopyInput = (props: { value: string }) => {

    const { value } = props


    return <div className="form-control">
        <div className="input-group">
            <input onChange={() => null} value={value} type="text" placeholder="Search…" className="w-full input input-bordered input-sm" />
            <CopyButton value={value} size={16} color="white"/>
        </div>
    </div>
}

export const SettingsPage = () => {

    const { currentUser } = useAppContext()
    const [referralCode, setReferralCode] = useState<string>('')
    const [generatingReferralLink, setGeneratingReferralLink] = useState<boolean>(false)

    // const [musicVolume, setMusicVolume] = useState<number>(50)
    // const [soundEffectsVolume, setSoundEffectsVolume] = useState<number>(50)

    // useEffect(()=>{
    //     const _musicVolume = parseFloat(localStorage.getItem(MUSIC_VOLUME_STORAGE_KEY) || '50')
    //     const _soundEffectsVolume = parseFloat(localStorage.getItem(SOUND_EFFECTS_VOLUME_STORAGE_KEY) || '50')

    //     setMusicVolume(_musicVolume)
    //     setSoundEffectsVolume(_soundEffectsVolume)

    //     localStorage.setItem(MUSIC_VOLUME_STORAGE_KEY, _musicVolume + '')
    //     localStorage.setItem(SOUND_EFFECTS_VOLUME_STORAGE_KEY, _soundEffectsVolume + '')

    // }, [])

    useEffect(() => {
        refresh()
    }, [])

    const refresh = async () => {
        try {

            const { code } = await UserApi.getReferralCode()

            setReferralCode(code)

        } catch (err) {
            if (axios.isAxiosError(err)) {
                toast('error', (err.response?.data as any).message as string)
            } else {
                toast('error', 'Something went wrong. Please contact support.')
                console.log(err)
            }
        }
    }

    const generateReferralCode = async () => {
        try {

            setGeneratingReferralLink(true)

            const { code } = await UserService.generateReferralCode()

            setReferralCode(code)

        } catch (err) {
            if (axios.isAxiosError(err)) {
                toast('error', (err.response?.data as any).message as string)
            } else {
                toast('error', 'Something went wrong. Please contact support.')
                console.log(err)
            }
        } finally {
            setGeneratingReferralLink(false)
        }
    }

    return (
        <ContentLayout heading="Settings">
            <div className='space-y-8 pb-4'>
                <div>
                    <p className="font-bold">Account</p>
                    <div className="form-control w-full max-w-sm">
                        <label className="label">
                            <span className="label-text">Your Payment Address:</span>
                        </label>
                        <CopyInput value={currentUser.payAddress} />
                    </div>
                    <div className="form-control w-full max-w-sm">
                        <label className="label">
                            <span className="label-text">Your Ordinal Address:</span>
                        </label>
                        <CopyInput value={currentUser.ordAddress} />
                    </div>


                </div>
                <div>
                    <div className="space-y-2 mb-2">

                        <p className="font-bold">Referral Program</p>

                        <p>
                            Welcome to our referral program! Earn a 5% commission for every initial NFT sale made through your unique referral link. Simply share your personalized link with others, and when they purchase an NFT using it, you'll receive a 5% commission on the sale amount. It's that easy!
                        </p>
                        <p>
                            Here's how it works:
                        </p>

                        <ol>
                            <li>
                                1. Share your referral link with friends, family, and followers.
                            </li>
                            <li>
                                2. When someone clicks on your link and purchases an initial NFT sale, you earn a 5% commission.
                            </li>
                            <li>
                                3. Your commission is automatically credited to your payment address.
                            </li>
                        </ol>

                        <p>Start sharing your referral link today and earn rewards for every NFT sale you help generate!</p>
                    </div>



                    <div className="form-control w-full max-w-sm">
                        <label className="label">
                            <span className="label-text">Your Referral Link:</span>
                        </label>
                        {!referralCode && <button
                            onClick={generateReferralCode}
                            className={classNames(
                                'btn btn-xs w-60',
                                generatingReferralLink ? 'loading' : ''
                            )}
                        >Generate a referral link</button>}
                        {referralCode && <CopyInput value={`${window.location.origin}/buy/${referralCode}`} />}
                    </div>
                </div>
                {/* <Divider />
                <div>
                    <p className="font-bold">Audio</p>
                    <div className="form-control w-full max-w-xs">
                        <label className="label">
                            <span className="label-text">Music</span>
                        </label>
                        <input
                            onChange={(e) => {
                                const volume = parseFloat(e.target.value)
                                setMusicVolume(volume)
                                localStorage.setItem(MUSIC_VOLUME_STORAGE_KEY, volume + '')
                                window.dispatchEvent(new Event(MUSIC_VOLUME_CHANGE_EVENT))
                            }}
                            type="range"
                            min="0"
                            max="100"
                            value={musicVolume}
                            className="range range-accent range-xs" />
                    </div>
                    <div className="form-control w-full max-w-xs">
                        <label className="label">
                            <span className="label-text">Sound Effects</span>
                        </label>
                        <input
                            onChange={(e) => {
                                const volume = parseFloat(e.target.value)
                                setSoundEffectsVolume(volume)
                                localStorage.setItem(SOUND_EFFECTS_VOLUME_STORAGE_KEY, volume + '')
                                window.dispatchEvent(new Event(SOUND_EFFECTS_VOLUME_CHANGE_EVENT))
                            }}
                            type="range"
                            min="0"
                            max="100"
                            value={soundEffectsVolume}
                            className="range range-accent range-xs" />
                    </div>
                </div> */}
            </div>
        </ContentLayout>
    );
}