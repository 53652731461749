import { createContext, useContext, useEffect } from "react";
import { useSearchDebounce } from "hooks/useSearchDebounce";
import { useNavigate } from "react-router-dom";

type NftDropsContextProps = {
    searchQuery: string;
    setSearchQuery: (searchQuery: string) => void
}

const NftDropsContext = createContext({
} as NftDropsContextProps);

export function NftDropsWrapper({ children }: { children: any }) {

    const navigate = useNavigate()
    const [searchQuery, setSearchQuery] = useSearchDebounce()


    useEffect(() => {
        if (searchQuery) {
            navigate('/nft')
        }
    }, [searchQuery])


    let sharedState: NftDropsContextProps = { searchQuery, setSearchQuery }

    return (
        <NftDropsContext.Provider value={sharedState}>
            {children}
        </NftDropsContext.Provider>
    );
}

export function useNftDropsContext() {
    return useContext(NftDropsContext);
}