import { ContentLayout } from "components/Layout.tsx/ContentLayout";

export const AboutPage = () => {
    return (
        <ContentLayout heading="About">
            <div className='space-y-4'>
                <p>
                    POK Business is a next generation blockchain game about player owned koi fish. The game features breeding which allows users to create and own new beautiful koi fish. Through selective breeding users can combine their koi fish granting them influence and the ability to refine the patterns and traits of their next generation of koi fish along with discovering new mutations. This allows users to explore an infinite number of procedurally generated traits and koi patterns. It could be advantageous to own beautiful koi fish since beauty contests are held several times a day where users from around the world can register their koi to compete. In addition to this the game also features pond management; a healthy pond means a happy koi. Users can manage factors such as the water temperature, filtration system, size and appearance of their pond.
                </p>
                <p>
                    Using Bitcoin SV, the original bitcoin as the backbone technology which is a fast, cheap and a reliable blockchain, enables users to have a seamless experience playing the game.
                </p>
            </div>
        </ContentLayout>
    );
}