import { Container, Texture, TilingSprite } from "pixi.js";

export class Stone {

    texture: Texture;
    tiling: TilingSprite;

    constructor(container: Container) {
        this.texture = Texture.from('stone')

        this.tiling = new TilingSprite(this.texture, container.width * 2, container.height * 2);

        this.tiling.scale.set(0.5)
        this.tiling.alpha = 0.2

        this.tiling.x = 0;
        this.tiling.y = 0;

        container.addChild(this.tiling)

    }

    resize(screenWidth: number, screenHeight: number) {
        if (this.tiling) {
            this.tiling.width = screenWidth * 2
            this.tiling.height = screenHeight * 2
        }
    }
}